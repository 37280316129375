import React, { useEffect, useState } from "react";
import style from "./FormsControll.module.css";
import { Field } from 'redux-form';

const FormFields = ({input, meta, ...props}) => {//Rest operatoriaus panaudojimas!!!!!!
   
    const hasError = meta.touched && meta.error;
    //  debugger;
    return (
        <div className={style.formControl + " " + (hasError?style.error: style.valid)}>
            <div>
            {props.children}
            </div>
        {hasError && <span>{meta.error}</span>}
        </div>
    )
}
export const Textarea = (props) => {//Rest operatoriaus panaudojimas!!!!!!
    
    const {input, meta, ...restprops} = props;
    // debugger;
    return (
        <FormFields {...props}>
            <textarea {...input} {...restprops}/>                    
        </FormFields>
    )
}
export const Input = (props) => {//Rest operatoriaus panaudojimas!!!!!!
    
    const {input, meta, disabled, ...restprops} = props;
    // debugger;
    return (
        <FormFields {...props}>
            <input disabled={disabled} {...input} {...restprops}/>                    
        </FormFields>
    )
}
export const Select = (props) => {
    // debugger;
    const {input, meta, disabled, gaidys, laukelis, laukelis1, laukelis2, ...restprops} = props;
    
    
    
    const options = gaidys.map((option) => {
        return <option 
                    // key={option[laukelis]}
                    key={Math.random()} 
                    value={option[laukelis]} 
                    //value={option}
                    // selected={selectedData===option}
                    
                >{laukelis1&&laukelis2?option[laukelis1]+" "+option[laukelis2]:option[laukelis]}
               </option>
    });
   
    // console.log(options);
    // useEffect(()=>{},[gaidys]);
    return (
        <FormFields {...props}>
            <select  disabled={disabled} {...input} {...restprops}>                       
            {options}
            </select>                    
        </FormFields>
    )
}








export const createField = (login= "", labelText, name1, placeholder, component, type, validators= [], props = {} ) => {
    // debugger;
    // const [selectedData, setSelectedData] = useState('višta');
    return (<div>
                <label htmlFor={login}>{labelText}</label>
                <Field name={name1} placeholder={placeholder} component={component} type={type} validate={validators} {...props}/>
            </div>)
}
export const createFieldT = (name1, placeholder, component, validators= [], props = {} ) => {
    return (<div>
                <Field name={name1} placeholder={placeholder} component={component} validate={validators} {...props}/>
            </div>)
}
export const createFieldTest = (name, component, type, labelText, disabled) => {
    return (<div>
                <label htmlFor={name}>{labelText}</label>
                <Field name={name} component={component} type={type} disabled ={disabled}/>
            </div>)
}