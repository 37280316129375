import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Preloader from '../Components/Preloader/Preloader';
import { Outlet } from 'react-router-dom';


let mapStateToPropsNavigate = (state) => {
    return {
        loading: state.data.loading,        
    }
}
let mapDispatchToProps = (dispatch) => {
    // debugger;
    return {
        // readUserThunkCreator:() => {dispatch(readUserThunkCreator());},
        // set_user_data: (id, login, email) => { dispatch(set_user_dataActionCreator(id, login, email)); },
    }
}

const Loading1 = (props) => {
    
const [loading, setLoading] = useState(true);
useEffect(()=>{
setTimeout(()=>{setLoading(false)}, 3000)
},[]);


    return (
        loading
            ? <Preloader />
            : <Outlet />            
    );
}

  
export const Loading = connect(mapStateToPropsNavigate, mapDispatchToProps)(Loading1);