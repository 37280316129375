import Preloader from "./Preloader/Preloader";

  export const Homepage = () => {
   
    return (
      <>
     <h1>Homepage</h1>
     {/* <Preloader/> */}
     </>
    );
  };
 