import { Link } from "react-router-dom";
import { Atsijungimas } from "./Header/Header1";
import { deleteUserThunkCreator } from "../redux/auth_Reducer.ts";
import { connect } from 'react-redux';
import { Card, Space } from "antd";

const Users1 = (props) => {
    return (
        <div> <h1>Vartotojai</h1>
            <Space
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}
            >
                <Card title="Prisijungimas" size="small">
                   
                    {/* <Link to='/login' >Prisijungti</Link> */}
                    <p>Jūs prisijungęs kaip: </p>
                    <p>{props.auth.email?props.auth.email:"Svečias"}</p>
                    {props.auth.email ? <Atsijungimas deleteUserThunkCreator={props.deleteUserThunkCreator} email={props.email} /> : <Link to='/login' >Prisijungti</Link>}
                </Card>
                <Card title="Naujo vartotojo kūrimas" size="small">
                <Link to='/signin' >Sukurti naują vartotoją</Link>
                    {/* <p>Card content</p> */}
                </Card>
                <Card title="Card" size="small">
                    <p>Card content</p>
                    <p>Card content</p>
                </Card>
            </Space>
        </div>
    )
};

// ***********Formos*********************???????????















// ********Kuriame konteinerį duomenų perdavimui į komponentą**********************

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
};
let mapDispatchToProps = (dispatch) => {
    return {
        deleteUserThunkCreator: () => { dispatch(deleteUserThunkCreator()); },
    }
}

export const Users = connect(mapStateToProps, mapDispatchToProps)(Users1);