import React from "react";
import { Field, reduxForm } from 'redux-form';
// // import { usersApi } from "../../../api/api";
// import { loginThunkCreator,  authThunkCreator} from "../../../redux/auth_Reducer.ts";
import { connect } from 'react-redux';
import { Input, createField} from "../common/FormsControll/FormsControll";
import { FileldRequired, MaxLength, Email } from "../utils/validators/FieldsValidators";
// import { Navigate } from 'react-router-dom';
import style from "./FormsControll.module.css";
import { loginThunkCreator } from "../../redux/auth_Reducer.ts";
import { Navigate, useLocation } from "react-router-dom";
// import { createField } from './../../common/Preloader/FormsControll/FormsControll';


const MaxLength1=MaxLength(30);
const LoginForm = (props) => {
    
    return (
        // handleSubmit metodas ateina iš HOC, vykdo 3 dalykus: prevent default; surenka visus laukelius į objektą ir paleižia išorinę perduodamą funkciją onSubmit, kurią mes perduodame apačioje
        <form onSubmit={props.handleSubmit}> 
            {createField("login", "Prisijungimo vardas", "email", "Vartotojo vardas", Input, "text", [FileldRequired, MaxLength1, Email] )}
            {createField("password", "Slaptažodis", "password", "Slaptažodis", Input, "password", [FileldRequired])}
            {createField("Atsiminti maned", "Atsiminti mane", "rememberMe", "", Input, "checkbox", [])}
         
            {/* {props.captcha && <img src={props.captcha}/> }
            {props.captcha && createField("captcha", "Captcha", "captcha", "captcha", Input, "text", [FileldRequired], {...props})} */}
           
            {props.error && <div className={style.formSummaryError}>
                {props.error}
                </div>}
            <div>
                <button>Prisijungti</button>
            </div>
        </form>
    )
}
const LoginReduxForm = reduxForm({ form: 'login' })(LoginForm)



const Login1 = (props) => {
    const location = useLocation();
    // console.log(location);
    // console.log(props);
    const fromPage = location.state?.from?.pathname || '/'
    const onSubmit = (formData)=>{// šitą funkciją perduodame per props į HOC form, kur handleSubmit ją paleidžia 
        //  console.log(formData);
        //  debugger;
        props.loginThunkCreator(formData);
        // alert("Login forma veikia");    
    }
    if (props.isAuth) {
       return (        
        <Navigate replace to={fromPage} /> //veikia nepaspaudus, t.y. redirectina. Tai vietoje Redirect
        )
    }
    return (<div>
        <h1>Prisijungti</h1>
        <LoginReduxForm onSubmit={onSubmit}
         
        // captcha={props.captcha}
        />
    </div>)
}
//************************** */

let mapStateToProps = (state) => {
    // debugger;
    return {
        isAuth:state.auth.isAuth,
        // captcha: state.auth.captcha
    }
}
// debugger;
let mapDispatchToProps = (dispatch) => {
    // debugger;
    return {
        loginThunkCreator:(data) => {dispatch(loginThunkCreator(data));},
        
        // set_user_data: (id, login, email) => { dispatch(set_user_dataActionCreator(id, login, email)); },
    }
}

const Login = connect(mapStateToProps, mapDispatchToProps)(Login1);

// const Login = connect(mapStateToProps, {loginThunkCreator,  authThunkCreator})(Login1);
//******************** */









export default Login;


