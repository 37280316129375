import React, { useEffect, useState } from "react";
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Select, createField } from "../../common/FormsControll/FormsControll";
import { MaxLength } from "../../utils/validators/FieldsValidators";
import style from "./Forms.module.css";
import { getDataThunkCreator, getMenesineThunkCreator } from "../../../redux/data_Reducer.ts";
import { useNavigate, useSearchParams } from "react-router-dom";

const MaxLength1 = MaxLength(30);
const PaieskaForm = (props) => {   
    const [selectedDataNuo, setSelectedDataNuo] = useState('');
    const [selectedDataIki, setSelectedDataIki] = useState('');
    const [options, setOptions] = useState([{periodo_data:"", namo_ID:"", adresas_gatve:"", adresas_namo_nr:""}]);
    const [options1, setOptions1] = useState([{periodo_data: '', namo_ID: '', adresas_gatve: '', adresas_namo_nr: ''}]);
    const [selectedDataNamoID, setSelectedDataNamoID] = useState("");
    

    // Reikia perrašyti handleSubmit funkciją, nes pagal default ji nepaima laukelių duomenų, kol jų nepaliečiame.
    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();
        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        // You can pass formData as a fetch body directly:
        // fetch('/some-api', { method: form.method, body: formData });
        // You can generate a URL out of it, as the browser does by default:
        // console.log(new URLSearchParams(formData).toString());
        // You can work with it as a plain object.
        const formJson = Object.fromEntries(formData.entries());
        // console.log(formJson); // (!) This doesn't include multiple select values
        // Or you can get an array of name-value pairs.
        // console.log([...formData.entries()]);
        // console.log([...formData]);
        props.onSubmit(formJson);
      };
    


    useEffect(() => {        
        // console.log(selectedDataNamoID);
        let d = options;
        // console.log(props.paieskosData);
        d = props.paieskosData.filter((val) => { return val.namo_ID == selectedDataNamoID })
        // console.log(d);
        // const a = d.map(value => { return value.periodo_data });
        setOptions(d);
        // console.log(options);
        // console.log(selectedDataNamoID); 
    }, [selectedDataNamoID, props.paieskosData]);

    useEffect(() => {
        // debugger;    
        props.getDataThunkCreator();
        // const a = props.paieskosData.map(value => { return value.namo_ID });
        // const unique = (value, index, arr) => { return arr.indexOf(value) === index };
        // const res = a.filter(unique);        
        
        const unique = (val1, index1, arr1) => {return arr1.findIndex(val2=>{ return val2.namo_ID==val1.namo_ID}) === index1 };
        const res = props.paieskosData.filter(unique);          
        // console.log(res);       
        setOptions1(res);
        setSelectedDataNamoID(res[0].namo_ID)
        // setOptions1(props.paieskosData); 
    }, [props.id]);
    // debugger;
    return (
        // handleSubmit metodas ateina iš HOC, vykdo 3 dalykus: prevent default; surenka visus laukelius į objektą ir paleižia išorinę perduodamą funkciją onSubmit, kurią mes perduodame apačioje
        <form onSubmit={handleSubmit} >
            {createField("namo_ID", "Namo ID", "Data3", "Namo ID", Select, "radio", [], { gaidys: options1, onChange: e => setSelectedDataNamoID(e.target.value), value: selectedDataNamoID, laukelis: 'namo_ID', laukelis1: "adresas_gatve", laukelis2: "adresas_namo_nr"})}
            {createField("datanuo", "Periodo pradžia", "Data1", "Periodo pradžia", Select, "radio", [], { gaidys: options, onChange: e => setSelectedDataNuo(e.target.value), value: selectedDataNuo, laukelis: 'periodo_data' })}
            {createField("dataiki", "Periodo pabaiga", "Data2", "Periodo pabaiga", Select, "radio", [], { gaidys: options, onChange: e => setSelectedDataIki(e.target.value), value: selectedDataIki, laukelis: 'periodo_data' })}
            {/* {props.captcha && <img src={props.captcha}/> }
            {props.captcha && createField("captcha", "Captcha", "captcha", "captcha", Input, "text", [FileldRequired], {...props})} */}

            {props.error && <div className={style.formSummaryError}>
                {props.error}
            </div>}
            <div>
                <button>Pateikti</button>
            </div>
        </form>
    )
}
const PaieskaReduxForm = reduxForm({ form: 'paieska' })(PaieskaForm)

const Paieska1 = (props) => {
    // let [searchParams, setSearchParams] = useSearchParams();``
    const navigate = useNavigate();


   

    const onSubmit = (formData) => {// šitą funkciją perduodame per props į HOC form, kur handleSubmit ją paleidžia
        // debugger;
        // console.log(formData);               
        navigate("/menesine", { state:formData});
        // alert("Submit veikia");
        // setSearchParams(formData1);
        // props.getMenesineThunkCreator(searchParams);     
    }   
    return (<div>
        <h1>Pateikti mėnesinę ataskaitą</h1>
        <PaieskaReduxForm onSubmit={onSubmit}
            paieskosData={props.paieskosData}
            getDataThunkCreator={props.getDataThunkCreator}
            id={props.id}
        />
    </div>)
}
//************************** */

let mapStateToProps = (state) => {
    // debugger;
    return {
        paieskosData: state.data.rezultatas,
        id: state.data.id
    }
}
// debugger;
let mapDispatchToProps = (dispatch) => {
    // debugger;
    return {
        getDataThunkCreator: () => { dispatch(getDataThunkCreator())},
        getMenesineThunkCreator: (data) => {dispatch(getMenesineThunkCreator(data))},        
    }
}
const Paieska = connect(mapStateToProps, mapDispatchToProps)(Paieska1);

//******************** */

export default Paieska;


