import { Link, useMatch } from "react-router-dom";

const ManoLink = ({children, to, setSelected, k, ...props}) => {
    const match = useMatch(to);
    // console.log(match);
    // console.log(match ? setSelected(k):{k});
    if (match) {setSelected(k)};
    // console.log(to);
    return (
        <Link
        to={to}        
        // style={{
        //     color: match ? 'var(--color-active)' : 'red',
        // }}
        {...props}
        >
        {children}
        </Link>
    )
}

export {ManoLink};