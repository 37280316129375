import React, { useEffect, useState } from "react";
import { withRouter } from "./with-router";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  let location = props.router.location;
  const [veryfyTime, setVeryfyTime] = useState("");
  
  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("authToken"));
   if (sessionStorage.getItem("authToken")) {
    const decodedJwt = parseJwt(sessionStorage.getItem("authToken"));// gal reikia iškelti iš usEffect????
  
    if (decodedJwt.exp * 1000 > Date.now()) {     
    const id = setInterval(()=>{      
    const t = decodedJwt.exp - Date.now() / 1000;
    const hours = Math.floor(t/60/60);
    const min = Math.floor(t/60%60);
    const sec = Math.floor(t%60);
    const time = hours + ": " + min + ": " + sec;
    setVeryfyTime(time);},1000);
  return () => clearInterval(id);
   }       
   };
    
  }, [veryfyTime]);


// let s = d.getHours()+": "+d.getMinutes()+": "+d.getSeconds();
// console.log(localStorage.getItem("authToken"));
  return <div><h1  style={{
    fontSize: '12px',
    width: 64,
    height: 20,
    color: "red",
    margin: '-20px 0px 0px 20px',
    padding: '0px 0px 0px 0px'
}} >{veryfyTime}</h1>

</div>;
};

export default withRouter(AuthVerify);

// export default AuthVerify;