import { Form, Select } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	BankOutlined,
	DoubleRightOutlined,
	DoubleLeftOutlined
} from '@ant-design/icons';





// let data = [{value:"Rolandas"}, {value:"Edita"}, {value:""}] 
// export const Blog = (props) => {
//     const Gaidys = useRef(null);
//     console.log(Gaidys);
//     return(
//         <>
//         <h1>Blog</h1>
//             <Form>
//                 <Form.Item label="Error" hasFeedback validateStatus="error">
//                     <Select
//                     ref={Gaidys}
//                     style={{
//                         width: 120,
//                     }}

//                     // onChange={(value) => { props.setSelectedDataNamoID(value) }}
//                     options={data}
//                     // defaultActiveFirstOption= {true}
//                     // defaultValue={}
//                     // value={props.selectedDataNamoID}
//                     // onSelect={(value)=>{props.setSelectedDataNamoID("Select")}}
//                     // onSearch={(value)=>{props.setSelectedDataNamoID("Search")}}
//                     // suffixIcon={<BankOutlined />}
//                     menuItemSelectedIcon={<BankOutlined />}
//                     placement={"topRight"}
//                     removeIcon={<BankOutlined />}
//                      />
//                 </Form.Item>
//             </Form>
//         </>
//     )
// }


// pvz su useMemo***********************************************************************************
// function createUser(name, surname) {
//     const user = { name, surname };
//     console.log(user);
//     return user
// }
// export const Blog = () => {
//     const [name, setName] = useState("")
//     const [surname, setSurname] = useState("")
//     const [counter, setCounter] = useState(0)
//     const user = useMemo(() => createUser(name, surname), [name, surname])
//     // const user = useMemo(() =>  {const user = { name, surname };
//     // console.log(user);
//     // return user}, [name, surname]) // Galimas ir toks variantas. Man tai aiškiau
//     return (
//         <div>
//             <button onClick={() => setCounter(counter + 1)}>
//                 Mane paspaudė {counter} kartų.
//             </button>
//             <input
//                 type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//     />
//             <br />
//             <input
//                 type="text"
//             value={surname}
//             onChange={(e) => setSurname(e.target.value)}
//     />
//         </div>
//     )
// }
// *********************************************************************************************************

// pvz su useCallback***************************************************************************************

// const element = (
//     <div>
//       <h1>Hello, world!</h1>
//       <h2>It is {new Date().toLocaleTimeString()}.</h2>
//     </div>
//   );

setTimeout(() => {
	console.log("setTimeout 1")
}, 0);


console.log("4");

setTimeout(() => {
	console.log("setTimeout 5");
	Promise.resolve().then(() => {
		console.log("Promise 6")
	});
	console.log("Po promiso 6")
}, 0);
setTimeout(() => {
	console.log("setTimeout 7")
}, 0);

console.log("2");
Promise.resolve().then(() => {
	console.log("Promise 3")
});



// export const Blog = () => {

// 	// const [counter, setCounter] = useState(0);
//     // const [message, setMessage] = useState("Labas" + counter);
// 	// const greeting = useCallback((text) => {
// 	// 	console.log(text);
// 	// }, []);

// 	// useEffect(() => {
// 	// 	greeting(message);
// 	// }, [greeting, message]);



// 	return (
// 		<>
// 		<h1>Labas</h1>
// 		  {/* {element}
// 		<button onClick={() => {setCounter(counter + 1); setMessage("Labas " + counter)}}>
// 			Paspausta {counter} kartų
// 		</button> */}
// 		</>
// 	);
//     }
// *********************************************************************************************************

export class Blog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			counter: 0,
		};

		// this.handlerClick = this.handlerClick.bind(this);
		// this.gaidys1 = this.gaidys1.bind(this);// arba galima bind čia!!!!!
	}

	handlerClick() {
		this.setState({ counter: this.state.counter + 1 });
	}
	gaidys1() {
		const a = 123;
		console.dir(this);
		this.setState({ counter: this.state.counter + 1 });

	}
	gaidys = () => {
		console.log("gaidys");
		console.dir(this);
		this.setState({ counter: this.state.counter + 1 });
	}

	render() {
		return (
			<button
				// onClick={() => this.setState({ counter: this.state.counter + 1 })}
				// onClick={this.gaidys} //veikia kai funkcija paskelbta kaip rodyklinė
				// onClick={()=>{this.gaidys1()}} // veikia kai čia perduoddama rodyklinė funkcija
				onClick={this.gaidys1.bind(this)} //veikia kai funkcija susieta bind
			>
				Paspausta {this.state.counter} раз.
			</button>
		);
	}
}
// export default Blog;


