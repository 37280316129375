import React from 'react';
import { Link } from "react-router-dom"
import {
    MenuFoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    MenuUnfoldOutlined,
    HomeOutlined,
    BarChartOutlined
} from '@ant-design/icons';

// import { useState } from 'react';
import logo from '../../images/logo3.png';
import style from '../css/style.module.css';
// import { NavLink } from 'react-router-dom';
import { Button, Image, Layout, Menu, theme, Col, Row, Space } from 'antd';
import AuthVerify from '../common/auth-verify';


const { Header, Sider, Content } = Layout;
export const Header1 = (props) => {
    // const Header = props.Header;
    const collapsed = props.collapsed;
    const setCollapsed = props.setCollapsed;
    return (
        <div className={style["topnav"]}>
        <div className={style["topnav-centered"]}>
            <h1 className={style["active"]}>Mano asmeninis tinklapis</h1>
        </div>
        <div className={style["topnav-left"]}>
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 35,
                    color: "red",
                    margin: '0px 0px 0px 0px'
                }} />
        </div>
        <div className={style["topnav-left"]}>
            <Image src={logo} width={150} preview={false}
                style={{
                    filter: "drop-shadow(3px 3px 10px red)",
                    color: "white",
                    padding: '0px 0px 0px 0px',
                    margin: '-10px 0px 0px 0px'
                }} />
        </div>

        <div className={style["topnav-right"]}>
            <a  style={{
                    fontSize: '14px',
                    width: 64,
                    height: 25,
                    color: "white",
                    margin: '0px 0px 0px 0px',
                    border: '1px solid red' 
            }}>{props.auth.email?props.auth.email:"Svečias"}</a>
            <div style={{
                    display: 'inline-block',
                    fontSize: '14px',
                    width: 84,
                    height: 65,
                    color: "white",
                    margin: '0px 0px 0px 0px',
                    padding: '0px 100px 0px 0px',
                    border: '1px solid red' 
            }}
            >{props.auth.email ? <Atsijungimas deleteUserThunkCreator={props.deleteUserThunkCreator} email={props.email} /> : <Link to='/login' >Prisijungti</Link>}</div>
        </div>       
    </div>
    )
}
export const Atsijungimas = (props) => {
 function clickfunc(event){
       event.preventDefault();
       props.deleteUserThunkCreator();
       // alert('Veikia');
     }
    return (
        <>
        <AuthVerify />
        <a onClick={clickfunc}>Atsijungti</a>
        </>
       
    )
}
