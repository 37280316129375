export const FileldRequired = (value) => {
    if (value) return undefined;
    return "Šis laukas yra privalomas";
}
export const MaxLength = (maxLength) => (value) => {
    if (value && value.length>maxLength) return 'Maksimalus simbolių skaičius yra: ' + maxLength;
    return undefined;
}
export const Email = (email) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.match(validRegex)) return undefined;
  
    return 'Neteisingai įvestas vartotojo vardas. Turi būti el. paštas';
}