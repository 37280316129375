import { Table, Image, Pagination } from "antd";
import { users as data } from "../DB/data";
import { Image1 } from "./Image";
import { Checkbox1 } from "./Checkbox";


export const Table1 = (props) => {
    const data1 = data.map(item => { return { ...item, key: item.id } }); // Būtinai reikalingas unikalus key!!!!!!!!!
    return (
        <div>
            <Table dataSource={data1} columns={columns} bordered={true} pagination={pagination} showHeader={true} size={'small'} title={() => { return "Gaidys (lentelės pavadinimas)" }} onRow={onRow} />
        </div>
    )
}
//paspaudus ar ... ant eilutės vykdo funkcijas
const onRow = (record, rowIndex) => {
    return {
        onClick: (event) => { alert("kažkas paspausta") }, // click row
        onDoubleClick: (event) => { }, // double click row
        onContextMenu: (event) => { }, // right button click row
        onMouseEnter: (event) => { }, // mouse enter row
        onMouseLeave: (event) => { }, // mouse leave row
    };
};
const pagination = {
    position: ['bottomLeft'],
    defaultPageSize: '3',
    showSizeChanger: true,
    pageSizeOptions: [2, 3, 5, 10, 20, 25, 50, 100]
};
// const masyvas = ["Kiaulė", "Katinas", "Šuo", "Gaidys"];
const masyvas1 = data.map(item => { return item.uniqueUrlName }); // filtravimo sąrašui sudaryti reikalingas masyvas su reikšmėmis
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;

        }, // Vardų rikiavimas

    },
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => { return a.id - b.id }, // rikiavimas
    },
    {
        title: 'uniqueUrlName',
        dataIndex: 'uniqueUrlName',
        key: 'uniqueUrlName',
        sorter: (a, b) => {
            const nameA = a.uniqueUrlName.toUpperCase(); // ignore upper and lowercase
            const nameB = b.uniqueUrlName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        },
        filterSearch: true,
        filters:             //Formuojame filtravimo menu
            masyvas1.map(item => { return { text: item, value: item } })
        //  [ {
        //         text: "Gaidys",
        //         value: "Gaidys"
        //     },
        //     {
        //         text: "Kiaulė",
        //         value: "Kiaulė"
        //     }]
        ,
        filterMode: 'tree',
        onFilter: ((value, item) => item.uniqueUrlName.includes(value)), // Filtravimo funkcija
        //   filterDropdownOpen: false,
        //   filteredValue:uniqueUrlName[],
        // filterMultiple:true,


    },
    {
        title: 'Foto',
        dataIndex: 'photos',
        key: 'photos',
        render: (photos) => { return <Image1 url={photos} /> }
    },
    {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'followed',
        dataIndex: 'followed',
        key: 'followed',
        render: (followed) => { return <Checkbox1 data={followed} /> }
    }
];