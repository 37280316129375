import { stopSubmit } from "redux-form";
import { userApi } from "../api/api";
import { Link, NavLink, redirect } from "react-router-dom";

// import { usersApi } from "../api/api";

const set_user_data:string = 'auth/set_user_data';
// const set_captcha = 'auth/set_captcha';
type stateType = {
  email: string | null,
  isAuth: boolean,
  role: null | number
}
let initialState:stateType = {
  // id: null,
  // login: null,
  email: null,
  isAuth: false,
  role: null
  // captcha: null
};
export const authReducer = (state = initialState, action) => {
  // let stateCopy;
  switch (action.type) {
    case set_user_data: {
      // debugger;
      return { ...state, ...action.userdata };
    }
    // case set_captcha: {
    //   return { ...state, captcha:action.data };
    // }
    default:
      return state;
  }
}

// export default authReducer;
// Action creators------------------------------------------------------------------------------------------------


const set_user_dataActionCreator = (data) => ({ type: set_user_data, userdata: { ...data, isAuth:true } });
const delete_user_dataActionCreator = (data) => ({ type: set_user_data, userdata: { ...data, isAuth:false } });
// const setcaptchaActionCreator = (data) => ({type: set_captcha, data});

// ---------------Thunks------------------------------------------------------------------------------------------
// export const authThunkCreator = () => {
//   // debugger;
//   return async (dispatch) => {

//     const data = await usersApi.auth()
//     if (data.resultCode == 0) {
//       // alert(data.resultCode);
//       dispatch(set_user_dataActionCreator(data.data.id, data.data.login, data.data.email, true));
//     } else { alert('Jūs neprisiloginęs ' + data.messages) };

//     // return "Gaidyssssss"
//   }
// }
export const loginThunkCreator = (data) => {
  // alert("formData.email");
  // debugger;
  return async (dispatch) => {
    const response = await userApi.login(data)
    // debugger;
    console.log(response);
    

    // // alert(data.resultCode + "Veikia");       
    if (response.status == 1) {
      // alert(response.message);
      // debugger;
      dispatch(set_user_dataActionCreator(response));
// Reikia išjungti formą!!!!!!!!!!!!!!!!!!!

      // dispatch(authThunkCreator());
      // dispatch(setcaptchaActionCreator(null));
    } else if (response.status == 0) {
      // dispatch(getcaptchaThunkCreator());
      alert(response.message);
    }
    else {
    //   let message = response.data.messages.length > 0 ? response.data.messages[0] : 'Kažkokia klaida';
    //   // alert(data.messages[1]);
    //   dispatch(stopSubmit("login", { _error: message }));// šitas metodas iš redux formos parodo formos klaidą jeigu tokia grąžinama iš serverio
    //   // alert('_error');
    // }
    // if (data.resultCode == 0) {
    // dispatch(set_user_dataActionCreator(data.data.id,data.data.login, data.data.email));
    //   }else {alert('Jūs neprisiloginęs')};      
  }
}
};
export const readUserThunkCreator = () => {
  // alert("formData.email");
  // debugger;
  return async (dispatch) => {
    const response = await userApi.readuser()
    // debugger;
    // console.log(response);
    const data = {
      id: null,
      login: null,
      email: null,
      isAuth: false
      // captcha: null
    }              
    // // alert(data.resultCode + "Veikia");       
    if (response.status == 1) {
      // alert(response.message);
      // debugger;
      dispatch(set_user_dataActionCreator(response));
// Reikia išjungti formą!!!!!!!!!!!!!!!!!!!

      // dispatch(authThunkCreator());
      // dispatch(setcaptchaActionCreator(null));
    } else if (response.status == 0) {
      // dispatch(getcaptchaThunkCreator());
      // alert(response.message);
      dispatch(delete_user_dataActionCreator(data));
    }
    else {
    //   let message = response.data.messages.length > 0 ? response.data.messages[0] : 'Kažkokia klaida';
    //   // alert(data.messages[1]);
    //   dispatch(stopSubmit("login", { _error: message }));// šitas metodas iš redux formos parodo formos klaidą jeigu tokia grąžinama iš serverio
    //   // alert('_error');
    // }
    // if (data.resultCode == 0) {
    // dispatch(set_user_dataActionCreator(data.data.id,data.data.login, data.data.email));
    //   }else {alert('Jūs neprisiloginęs')};      
  }
}
};
export const deleteUserThunkCreator = () => {  
  return async (dispatch) => {
    const response = await userApi.deleteuser()
    // console.log(response); 
    const data = {
        id: null,
        login: null,
        email: null,
        isAuth: false,
        role: null
        // captcha: null
      }          
    if (response.status == 1) {
      
      // debugger;
      dispatch(delete_user_dataActionCreator(data));
    } else if (response.status == 0) {     
      // alert(response.message);
      dispatch(delete_user_dataActionCreator(data));
    }
    else {
      // alert(response.message);
  }
}
};
export const signinThunkCreator = (userdata) => {  
  return async (dispatch) => {
    const response = await userApi.signinuser(userdata)
    // console.log(response); 
    const data = {
        id: null,
        login: null,
        email: null,
        isAuth: false
        // captcha: null
      }          
    if (response.status == 1) {
      alert(response.message);
      // debugger;
      // dispatch(delete_user_dataActionCreator(data));
    } else if (response.status == 0) {     
      alert(response.message);
      // dispatch(delete_user_dataActionCreator(data));
    }
    else {
      // alert(response.message);
  }
}
};