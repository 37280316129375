import { Checkbox } from 'antd';



const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};


export const Checkbox1 = ({data}, {...props}) => {
return (
    <Checkbox onChange={onChange} checked={data}>Checkbox</Checkbox>
)
};
