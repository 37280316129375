import React from "react";
import { Field, reduxForm } from 'redux-form';
// // import { usersApi } from "../../../api/api";
// import { loginThunkCreator,  authThunkCreator} from "../../../redux/auth_Reducer.ts";
import { connect } from 'react-redux';
import { Input, createField} from "../common/FormsControll/FormsControll";
import { FileldRequired, MaxLength, Email } from "../utils/validators/FieldsValidators";
// import { Navigate } from 'react-router-dom';
import style from "./FormsControll.module.css";
import { signinThunkCreator } from "../../redux/auth_Reducer.ts";
import { Navigate, useNavigate } from "react-router-dom";
// import { createField } from './../../common/Preloader/FormsControll/FormsControll';


const MaxLength1=MaxLength(30);
const SigninForm = (props) => {
    const newUser = {
        confirmpassword:"senamyja",
        email:"rolandas.macius@gmail.com",
        name:"Rolandas",
        password:"senamyja",
        pavarde:"Macius"
}       
    return (

        // handleSubmit metodas ateina iš HOC, vykdo 3 dalykus: prevent default; surenka visus laukelius į objektą ir paleižia išorinę perduodamą funkciją onSubmit, kurią mes perduodame apačioje
        <form onSubmit={props.handleSubmit}> 
        <p>Reikia patikrinimo kad sutaptų password laukeliai</p>
            {createField("login", "Prisijungimo vardas", "email", "Vartotojo vardas", Input, "text", [FileldRequired, MaxLength1, Email], {...props})}
            {createField("name", "Vardas", "name", "Vardas", Input, "text", [FileldRequired, MaxLength1], {...props})}
            {createField("pavarde", "Pavardė", "pavarde", "Pavardė", Input, "text", [FileldRequired, MaxLength1], {...props})}
            {createField("password", "Slaptažodis", "password", "Slaptažodis", Input, "password", [FileldRequired], {...props})}
            {createField("confirmpassword", "Slaptažodis", "confirmpassword", "Pakartokite slaptažodį", Input, "password", [FileldRequired], {...props})}
            
            {/* {createField("Atsiminti maned", "Atsiminti mane", "rememberMe", "", Input, "checkbox", [], {...props})} */}
         
            {/* {props.captcha && <img src={props.captcha}/> }
            {props.captcha && createField("captcha", "Captcha", "captcha", "captcha", Input, "text", [FileldRequired], {...props})} */}
           
            {props.error && <div className={style.formSummaryError}>
                {props.error}
                </div>}
            <div>
                <button>Siųsti</button>
            </div>
        </form>
    )
}
const SigninReduxForm = reduxForm({ form: 'signin' })(SigninForm)



const Signin1 = (props) => {
    const navigate = useNavigate();
    const onSubmit = (formData)=>{// šitą funkciją perduodame per props į HOC form, kur handleSubmit ją paleidžia 
        // debugger;
        
        props.signinThunkCreator(formData);
        // if (statusas== 1) alert("statusas veikia");
        navigate('/users');
    }
    // return <Navigate replace to="/users" />   // reikia dar išvesti žinutę, kad vartotojas sukurtas sėkmingai    
    return (<div>
        <h1>Sukurti naują vartotoją</h1>
        <SigninReduxForm onSubmit={onSubmit}
         
        // captcha={props.captcha}
        />
    </div>)
}
//************************** */

let mapStateToProps = (state) => {
    // debugger;
    return {
        // isAuth:state.auth.isAuth,
        // captcha: state.auth.captcha
    }
}
// debugger;
let mapDispatchToProps = (dispatch) => {
    // debugger;
    return {
        signinThunkCreator:(data) => {dispatch(signinThunkCreator(data));},
        
        // set_user_data: (id, login, email) => { dispatch(set_user_dataActionCreator(id, login, email)); },
    }
}

const Signin = connect(mapStateToProps, mapDispatchToProps)(Signin1);
export default Signin;


