import { Header1 } from "./Header1"
import { connect } from 'react-redux';


import { Button, Image, Layout, Menu, theme, Col, Row, Space } from 'antd';
import {
    MenuFoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    MenuUnfoldOutlined,
    HomeOutlined,
    BarChartOutlined
} from '@ant-design/icons';
import logo from '../../images/logo2.png';
import { deleteUserThunkCreator } from "../../redux/auth_Reducer.ts";



const HeaderContainer = (props) => {
        
    return (
       <Header1 auth={props.auth} collapsed={props.collapsed} setCollapsed={props.setCollapsed} deleteUserThunkCreator={props.deleteUserThunkCreator}/>
    ) 
        
    
}




const mapStateToProps = (state) => {
    // debugger;
    return {
        auth:state.auth,
        }
}


let mapDispatchToProps = (dispatch) => {
   return {
        deleteUserThunkCreator: () => { dispatch(deleteUserThunkCreator()); },
        // textAreaChange1: (text) => { dispatch(updateNewPostTextActionCreator(text)); },
        // set_UserProfile: (userProfile) => { dispatch(set_UserProfileActionCreator(userProfile)); },
        //     GetProfileThunkCreator:GetProfileThunkCreator
    }
}

export const HeaderContainer1 = connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
