import { applyMiddleware, combineReducers, compose } from "redux";
import { legacy_createStore as createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import {authReducer} from "./auth_Reducer.ts";
import {dataReducer} from "./data_Reducer.ts";
// import dataReducer from "./data_Reducer.ts";



let reducers = combineReducers({
    data: dataReducer,
    // postspage: postMessagesReducer,
    // userspage: usersReducer,
    auth: authReducer,
    // app: appReducer,
    form: formReducer
         
});


//Šitas kodas reikalingas redux priedui chrome naršyklėje

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(
    applyMiddleware(thunkMiddleware)
  ));
//---------------------------------------------
// let store = createStore(reducers, applyMiddleware(thunkMiddleware));
//************************************************************************* */
// console.log(store.getState());
// let store = configureStore(reducers);

window.store=store;

export default store;