import React, { useEffect, useState } from "react";
import {Form, Button, Select, Space } from 'antd';
import { getDataThunkCreator, getMenesineThunkCreator } from "../../../redux/data_Reducer.ts";
import { connect } from 'react-redux';
import {  useNavigate } from "react-router-dom";
import {
    BankOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    CloseOutlined
} from '@ant-design/icons';

const handleChange = (value) => {
    // console.log(`selected ${value}`);
};



let Gaidys = [
    {
        values: 'jack',
        labels: 'Jack',
        valeasa: 'jack',
        labelsscasc: 'Jack',
    },
    {
        dfvdf: 'sdfvfdv',
        values: 'lucy',
        labels: 'Lucy',
    },
    {
        dfvdf: 'sdfvfdv',
        values: 'Yiminghe',
        labels: 'yiminghe',
    },
    {
        values: 'disabled',
        labels: 'Disabled',
        disabled: true,
    },
];
const getOptions = (labels, values, arr, labels2) => {
    const options = [];
    if (labels2) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].disabled) {
                options.push({
                    label: arr[i][labels] + " " + arr[i][labels2],
                    value: arr[i][values],
                    disabled: true
                });
                break;
            }
            options.push({
                label: arr[i][labels] + " " + arr[i][labels2],
                value: arr[i][values]
            });
        }
    } else {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].disabled) {
                options.push({
                    label: arr[i][labels],
                    value: arr[i][values],
                    disabled: true
                });
                break;
            }
            options.push({
                label: arr[i][labels],
                value: arr[i][values]
            });
        }
    }

    return options;
}

const Paieska1 = (props) => {
    //   console.log(props.optionsNamoID[0]);
    // console.log(optionsNamoID);
    return (
        <>
                <Space wrap>

                    <Select
                        // optionLabelProp={"label"}
                        style={{
                            width: 120,
                        }}

                        onChange={(value) => { props.setSelectedDataNamoID(value) }}
                        options={props.optionsNamoID}
                        // defaultActiveFirstOption= {true}
                        // defaultValue={}
                        value={props.selectedDataNamoID}
                        // onSelect={(value)=>{props.setSelectedDataNamoID("Select")}}
                        // onSearch={(value)=>{props.setSelectedDataNamoID("Search")}}
                        // suffixIcon={<BankOutlined />}
                        menuItemSelectedIcon={<BankOutlined />}
                        placement={"topRight"}
                        removeIcon={<BankOutlined />}
                        allowClear
                        clearIcon={<CloseOutlined />}
                    />
                    <Select

                        style={{
                            width: 180,
                        }}
                        // disabled
                        loading={props.selectedDataNuo ? false : true}
                        options={props.optionsData}
                        onChange={(value) => { props.setSelectedDataNuo(value) }}
                        value={props.selectedDataNuo}
                        menuItemSelectedIcon={<DoubleRightOutlined />}
                        placement={"topLeft"}

                    />
                    <Select
                        // defaultValue=""
                        style={{
                            width: 180,
                        }}
                        loading={props.selectedDataIki ? false : true}
                        options={props.optionsData}
                        onChange={(value) => { props.setSelectedDataIki(value) }}
                        value={props.selectedDataIki}
                        menuItemSelectedIcon={<DoubleLeftOutlined />}
                        // notFoundContent={<DoubleRightOutlined />}
                        placement={"topLeft"}
                    />

                    <Button
                        disabled={false?true:false}  // bet statusą tikrinasi tik kai renderinasi
                        type="primary"
                        loading={false}
                        onClick={() => props.onSubmit()}>
                        Click me!
                    </Button>
                </Space>
           {/* <Form>
           </Form> */}

                </>
    );

}
const Paieska2 = (props) => {
    // let [searchParams, setSearchParams] = useSearchParams();``
    const navigate = useNavigate();
    const [selectedDataNuo, setSelectedDataNuo] = useState('');
    const [selectedDataIki, setSelectedDataIki] = useState('');
    const [optionsData, setOptionsData] = useState([{ label: "", value: "" }]);
    const [optionsNamoID, setOptionsNamoID] = useState([{ label: "", value: "" }]);
    const [selectedDataNamoID, setSelectedDataNamoID] = useState("");


    useEffect(() => {
        // // debugger; 
        console.log("Suveikimas"+ props.id);   
        props.getDataThunkCreator();
        const unique = (val1, index1, arr1) => { return arr1.findIndex(val2 => { return val2.namo_ID == val1.namo_ID }) === index1 };
        const res = props.paieskosData.filter(unique);

        console.log(res);
        // console.log(getOptions("adresas_gatve","namo_ID", res, "adresas_namo_nr"));
        const namoID = getOptions("adresas_gatve", "namo_ID", res, "adresas_namo_nr");

        setOptionsNamoID(namoID);
        setSelectedDataNamoID(namoID[0].value)
        // console.log(namoID[0].value);
        // setSelectedDataNamoID(res[0].namo_ID)
        // setOptions1(props.paieskosData); 
    }, [props.id]);

    useEffect(() => {
        // console.log(optionsNamoID);
        let d = optionsData;
        // console.log(props.paieskosData);
        d = props.paieskosData.filter((val) => { return val.namo_ID == selectedDataNamoID })
        // console.log(getOptions("periodo_data","periodo_data", d));
        // // const a = d.map(value => { return value.periodo_data });
        const data = getOptions("periodo_data", "periodo_data", d);
        setOptionsData(data);
        // console.log(data[0]?data[0].value:"Tuščia");
        data[0] ? setSelectedDataNuo(data[0].value) : console.log("Data nuo nenustatyta");
        data[0] ? setSelectedDataIki(data[0].value) : console.log("Data iki nenustatyta");
        // setSelectedDataNuo(data[0].value);
        // setSelectedDataIki(data[0].value);
        // console.log(optionsData);
        // console.log(selectedDataNamoID); 
        console.log("Suveikimas 2");
    }, [selectedDataNamoID, props.paieskosData]);

    const onSubmit = () => {// šitą funkciją perduodame per props į HOC form, kur handleSubmit ją paleidžia
        // debugger;
        // console.log(formData);
        const data = { Data1: selectedDataNuo, Data2: selectedDataIki, Data3: selectedDataNamoID }
        navigate("/menesine", { state: data });
        // alert("Submit veikia");
        // setSearchParams(formData1);
        // props.getMenesineThunkCreator(searchParams);     
    }
    return (<div>
        <h1>Pateikti mėnesinę ataskaitą</h1>
        <Paieska1
            optionsNamoID={optionsNamoID}
            selectedDataNamoID={selectedDataNamoID}
            setSelectedDataNamoID={setSelectedDataNamoID}
            optionsData={optionsData}
            setSelectedDataNuo={setSelectedDataNuo}
            setSelectedDataIki={setSelectedDataIki}
            selectedDataNuo={selectedDataNuo}
            selectedDataIki={selectedDataIki}
            onSubmit={onSubmit}

        // paieskosData={props.paieskosData}
        // getDataThunkCreator={props.getDataThunkCreator}
        // id={props.id}
        />
    </div>)
}
//************************** */

let mapStateToProps = (state) => {
    // debugger;
    return {
        paieskosData: state.data.rezultatas,
        id: state.data.id
    }
}
// debugger;
let mapDispatchToProps = (dispatch) => {
    // debugger;
    return {
        getDataThunkCreator: () => { dispatch(getDataThunkCreator()) },
        getMenesineThunkCreator: (data) => { dispatch(getMenesineThunkCreator(data)) },
    }
}
export const PaieskaA = connect(mapStateToProps, mapDispatchToProps)(Paieska2);

//******************** */

// export default PaieskaA;