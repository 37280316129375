import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { reduxForm } from 'redux-form';
import { bandymasContext } from './../index.tsx';
import { useContext } from 'react';

export const Email1 = (props) => {
  const form = useRef();
  const [file, setFile] = useState(null);
  const addFile = () => {
    // inputFile.current.click();
    alert("Add file");
  };


  const sudetingaFunkcija = (num) => {
    let i = 0;
    while (i < 1000000000) i++;
    return num * 2;
  };

  //  Komponentas Itemlist****************
  function ItemsList({ getItems }) {
    const [items, setItems] = useState([])

    useEffect(() => {
      const newItems = getItems(15)
      setItems(newItems)
      console.log('render')
    }, [getItems])
    return (
      <ul>
        {items.map(i => <li key={i}>{i}</li>)}
      </ul>
    )
  }
  // ****************************************
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form);
    // const emailParams = {
    //   to_name: "rolandas.macius@gmail.com",
    //   from_name: "Rolas",
    //   message: "Email message here",
    //   file: reader.result
    // };
    // debugger;
    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    emailjs.sendForm('service_c76rcy9', 'template_dt7balj', form.current, 'zzkxH7W89Irbwg8fS')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };





  const onSubmit = (formData, e) => {// šitą funkciją perduodame per props į HOC form, kur handleSubmit ją paleidžia 
    console.log({ ...formData });
    alert('onSubmit veikia!!!')
    sendEmail(e);
  };


  // Reikia perrašyti handleSubmit funkciją, nes pagal default ji nepaima laukelių duomenų, kol jų nepaliečiame.
  function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    // You can pass formData as a fetch body directly:
    // fetch('/some-api', { method: form.method, body: formData });
    // You can generate a URL out of it, as the browser does by default:
    // console.log(new URLSearchParams(formData).toString());
    // You can work with it as a plain object.
    const formJson = Object.fromEntries(formData.entries());
    // console.log(formJson); // (!) This doesn't include multiple select values
    // Or you can get an array of name-value pairs.
    // console.log([...formData.entries()]);
    // console.log([...formData]);
    onSubmit(formData, e);

    // const reader = new FileReader();
    // reader.readAsDataURL(file);

    // reader.onload = async (e) => {

    //   alert('Kažkas veikia')
    // }




  };
  // debugger;
  // Kontekstas
  const bandymoKontekstas = useContext(bandymasContext); //Čia gauname objektą su perduotais value {Gaidys, Višta}
  console.log(bandymoKontekstas)
  //*************************************** */

  // useReff*********************
  const [value, setValue] = useState('pradinė reikšmė')
  const refasCount = useRef(1)
  const refas = useRef("null");

  useEffect(() => {
    refasCount.current++;
    //  console.log(refas);

  })
  function changeRef(e) {
    console.log(refas.current.value);
    // console.log(refasCount);
    // setValue(e.target.value);
  }
  const focus = () => {
    refas.current.focus();
  }
  //  **********************************************

  // ******useMemo
  const [skaicius, setSkaicius] = useState(10);

  // const rezultatas = sudetingaFunkcija(skaicius); // be useMemo funkcija leidžiasi visada kai renderinasi puslapis
  const rezultatas = useMemo(() => {
    return sudetingaFunkcija(skaicius);
  }, [skaicius]);
  // useMemo šitą funkciją leidžia tik tada kai renderinasi puslapis ir pasikeičia state skaicius 


  const [colored, setColored] = useState(false);
  const styles = useMemo(() => { return { color: colored ? "black" : "red" } }, [colored]) //styles keičiasi tik tada kai keičiasi state colored ir tik tada gali suveikti useEffect


  useEffect(() => {
    console.log("Pasikeitė stilius");
  }, [styles])
  // Visada rodo kad pasikeitė stilius, nes kiekvieną kartą renderinantis style objektas kuriamas iš naujo ir senas objektas nelygus naujam objektui, todėl jis skaitosi pasikeitęs. Reikia Memizuoti pati styles objektą
  // *****************************************

  // *********useCallback

  const generateItemsFromAPI = useCallback((indexNumber) => {
    return new Array(skaicius).fill('').map((_, i) => `Elementas ${i + indexNumber}`)
  }, [skaicius])



  return (
    <bandymasContext.Consumer>


      {({ Gaidys, Višta }) => (
        <>



          <h1>useCallback</h1>
          <h1 style={styles}>Elementų kiekis: {skaicius}</h1>
          {/* <button className={'btn btn-success'} onClick={() => setSkaicius(prev => prev + 1)}>Pridėti</button>
      <button className={'btn btn-warning'} onClick={() => setColored(prev => !prev)}>Keisti</button> */}

          <ItemsList getItems={generateItemsFromAPI} />

          <h1>useMemo bandymai nerenderint puslapio</h1>
          <h1 style={styles}>{rezultatas}</h1>
          <h1>{skaicius}</h1>
          <button onClick={() => { setSkaicius(prev => prev + 1) }}>Plius</button>
          <button onClick={() => { setSkaicius(prev => prev - 1) }}>Minus</button>
          <button onClick={() => { setColored(prev => !prev) }}>Keisti spalvą</button>
          <h1>useRef bandymai nerenderint puslapio</h1>
          <h1>{refasCount.current}</h1>
          <input type="text" onChange={changeRef} ref={refas} value={refas.current.value} /> 
          {/* Kai value prilyginam ref current value komponentas nesirenderina. Kai šdedame reikšmę iš useState, tada kiekvienu state pasikeitimu renderinasi komponentas */}
          <button onClick={focus}>Focus to input</button>
          <h1>Conext bandymai</h1>
          <h1>{Gaidys}</h1>
          <h1>{Višta}</h1>
          <h1>{bandymoKontekstas.Višta}</h1>
          <form
            ref={form}
            // onSubmit={sendEmail}
            onSubmit={handleSubmit}
          >
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />

            <input
              name="image"
              type="file"
              onClick={addFile}
              // ref={"inputFile"}
              // value={"file"}
              // onChange={(event: any) => setFile(event.target.value)}
              onChange={(e) => setFile(e.target.files[0])}
            />

            <input type="submit" value="Send" />
          </form>
        </>

      )}

    </bandymasContext.Consumer>

  )
};
const Email2 = reduxForm({ form: 'emailForm' })(Email1);
export const Email = (props) => {

  return (
    <Email2 />
  )
};