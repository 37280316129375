import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import useAuth from '../Hooks/useAuth';
import { connect } from 'react-redux';
const { Dragger } = Upload;
const props = {
  name: 'file',
  multiple: true,
  action: 'https://backend/failas',  
  // action: 'info',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};
const Admin1 = (props) => {
  // <Dragger {...props}>
  //   <p className="ant-upload-drag-icon">
  //     <InboxOutlined />
  //   </p>
  //   <p className="ant-upload-text">Click or drag file to this area to upload</p>
  //   <p className="ant-upload-hint">
  //     Support for a single or bulk upload. Strictly prohibited from uploading company data or other
  //     banned files.
  //   </p>
  // </Dragger>

  const {auth, setAuth } = useAuth();
  let authProps = props.isAuth;
  const roleProps = props.role;  
  // setAuth({authProps, roleProps});
    console.log(auth);
    // setAuth(["Višta"])


  return (
    <>
    <h1>Admin puslapis</h1>

    <h3>Custom hook useAuth demonstarcija</h3>

    <h4>{props.role?props.role:"authProps"}</h4>

    <button onClick={()=>{setAuth("Višta")}}>Paspausti</button>
  </>
  )
};


let mapStateToPropsNavigate = (state) => {
  return {
      isAuth: state.auth.isAuth,
      role: state.auth.role,
  }
}
let mapDispatchToProps = (dispatch) => {
  // debugger;
  return {
      // readUserThunkCreator:() => {dispatch(readUserThunkCreator());},
      // set_user_data: (id, login, email) => { dispatch(set_user_dataActionCreator(id, login, email)); },
  }
}

const Admin = connect(mapStateToPropsNavigate, mapDispatchToProps)(Admin1);








export default Admin;