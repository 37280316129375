import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { AuthProvider } from './Context/AuthProvider';
import { createContext } from 'react';



export const bandymasContext: any = createContext('value');

const rootElement: string = "root";
const root = ReactDOM.createRoot(document.getElementById(rootElement)!); // createRoot(container!) if you use TypeScript// Šauktukas nurodo, kad argumentas nebus lygus 0.
// const root = ReactDOM.createRoot(document.getElementById('root'));
// debugger;
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <bandymasContext.Provider value={{ Gaidys: "Rodomas gaidys", Višta: "Rodoma višta" }}>
          <App />
        </bandymasContext.Provider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



