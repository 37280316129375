import { stopSubmit } from "redux-form";
import { dataApi, userApi } from "../api/api";
import { Link, NavLink, redirect } from "react-router-dom";
// import { usersApi } from "../api/api";

const set_data:string = 'data/set_data';
const set_menesine_data:string = 'data/set_menesine_data';
const set_menesine_raw_data:string = 'data/set_menesine_raw_data';
const set_menesineKV:string = 'data/set_menesineKV';
const set_menesineSD:string = 'data/set_menesineSD'

type initialStateType = {
  loading: boolean,
  status: number | null,
  message: string,
  id: number | null,
  firs_name: string,
  last_name: string,
  email: string,
  Gaidys: string,
  menesine:menesineType,
  menesineraw:menesinerawType,
  menesineKV:{
      adresas_gatve:string,
      adresas_namo_nr:string,
      buto_Nr: string,
      skaitiklio_Nr: string,
      skaitiklio_marke: string,
      rodmenys1: number | null,
      pradzia: string | null,
      pradzia_laikas: string | null,
      kambarys: string,
      rad_galia: number | null,
      rad_medziaga: number | null,
      kt: number | null,
      kamb_padetis: number | null,
      rodmenys2: number | null,
      pabaiga: string | null,
      pabaiga_laikas: string | null,
      key: number | null,
      rodmenys1red: number | null,
      rodmenys2red: number | null,
      skirtumasred: number | null,
      butasviso: number | null,
  }[],
  menesineSD:{
      adresas_gatve:string,
      adresas_namo_nr:string,
      buto_Nr: string | null,
      skaitiklio_Nr: string | null,
      skaitiklio_marke: string | null,
      rodmenys1: number | null,
      pradzia: string | null,
      pradzia_laikas: string | null,
      kambarys: string | null,
      rad_galia: number | null,
      rad_medziaga: number | null,
      kt: number | null,
      kamb_padetis: number | null,
      rodmenys2: number | null,
      pabaiga: string | null,
      pabaiga_laikas: string | null,
      key: number | null,
      rodmenys1red: number | null,
      rodmenys2red: number | null,
      skirtumasred: number | null,
      butasviso: number | null
  }[],
  // rezultatas:rezultatasType
  rezultatas:rezultatasType[] // su antru variantu
};
type menesineType = {
  status: number | null,
  message: string,
  id: number | null,
  firs_name: string,
  last_name: string,
  email: string,
  rezultatas: rezultatasMenesineType,
  sanaudos_red_viso: number | null,
  pradzios_data: string,
  pabaigos_data: string,
  pradzios_data1: string,
  pabaigos_data1: string
};

//  galimi trys rašymo variantai

// type rezultatasType = {    
//   periodo_data:string,
//   namo_ID:number | null,
//   adresas_gatve:string,
//   adresas_namo_nr:string  
// }[];

type rezultatasType = {    
  periodo_data:string,
  namo_ID:number | null,
  adresas_gatve:string,
  adresas_namo_nr:string  
};


// type rezultatasType = Array<{    
//   periodo_data:string,
//   namo_ID:number | null,
//   adresas_gatve:string,
//   adresas_namo_nr:string  
// }>;



// Array<typeof item2>

type rezultatasMenesineType = {    
  buto_Nr:string ,
  kambarys: string ,
  skaitiklio_Nr: string ,
  rad_galia: number | null ,
  rad_medziaga: number | null ,
  kt: number | null ,
  kamb_padetis: number | null ,
  rodmenys1: number | null ,
  rodmenys1_red: number | null ,
  pradzia: string ,
  pradzia_laikas: string ,
  rodmenys2: number | null ,
  rodmenys2_red: number | null ,
  pabaiga: string ,
  pabaiga_laikas: string ,
  sanaudos: number | null ,
  sanaudos_redaguotos: number | null ,
  buto_sanaudos: number | null
}[];
type menesinerawType = {    
    adresas_gatve:string,
    adresas_namo_nr:string,  
    buto_Nr:string ,
    kambarys: string | null,
    skaitiklio_Nr: string ,
    rad_galia: number | null ,
    rad_medziaga: number | null ,
    kt: number | null ,
    kamb_padetis: number | null ,
    rodmenys1: number | null ,
    pradzia: string ,
    pradzia_laikas: string ,
    rodmenys2: number | null ,
    pabaiga: string ,
    pabaiga_laikas: string ,
    skaitiklio_marke: string   
    }[]

let initialState:initialStateType = {
  loading: false,
  status: 0,
  message: "",
  id: null,
  firs_name: "",
  last_name: "",
  email: "",
  Gaidys: "",
  menesine:{
    status: null,
    message: "",
    id: null,
    firs_name: "",
    last_name: "",
    email: "",
    rezultatas: [{
      buto_Nr: "",
      kambarys: "",
      skaitiklio_Nr: "",
      rad_galia: null,
      rad_medziaga: null,
      kt: null,
      kamb_padetis: null,
      rodmenys1: null,
      rodmenys1_red: null,
      pradzia: "",
      pradzia_laikas: "",
      rodmenys2: null,
      rodmenys2_red: null,
      pabaiga: "",
      pabaiga_laikas: "",
      sanaudos: null,
      sanaudos_redaguotos: null,
      buto_sanaudos: null
      },        
    ],
    sanaudos_red_viso: null,
    pradzios_data: "",
    pabaigos_data: "",
    pradzios_data1: "",
    pabaigos_data1: ""
  },
  menesineraw: [{
      adresas_gatve: "Kviečių",
      adresas_namo_nr: "123",
      buto_Nr: "1",
      skaitiklio_Nr: "51693664",
      skaitiklio_marke: "6",
      rodmenys1: 0.191,
      pradzia: "2023-09-01",
      pradzia_laikas: "03:28:00",
      kambarys: null,
      rad_galia: null,
      rad_medziaga: null,
      kt: null,
      kamb_padetis: null,
      rodmenys2: 0.191,
      pabaiga: "2023-09-01",
      pabaiga_laikas: "03:28:00"
  },],  
  menesineKV: [{
      adresas_gatve: "Kviečių",
      adresas_namo_nr: "123",
      buto_Nr: "1",
      skaitiklio_Nr: "51693664",
      skaitiklio_marke: "6",
      rodmenys1: null,
      pradzia: null,
      pradzia_laikas: null,
      kambarys: "Karšto vandens skaitiklis1",
      rad_galia: null,
      rad_medziaga: null,
      kt: null,
      kamb_padetis: null,
      rodmenys2: null,
      pabaiga: null,
      pabaiga_laikas: null,
      key: 51693664,
      rodmenys1red: 0,
      rodmenys2red: 0,
      skirtumasred: 0,
      butasviso: 0
  },
  ],
  menesineSD:[{
        adresas_gatve: "Kviečių",
        adresas_namo_nr: "123",    
        buto_Nr: "1",
        skaitiklio_Nr: "79680449",
        skaitiklio_marke: "8",
        rodmenys1: 818.000,
        pradzia: "2023-09-30",
        pradzia_laikas: "11:43:00",
        kambarys: "1 kambarys",
        rad_galia: 1.673,
        rad_medziaga: 1.12,
        kt: 1,
        kamb_padetis: 0.85,
        rodmenys2: 0.000,
        pabaiga: "2023-10-12",
        pabaiga_laikas: "13:45:00",
        key: 79680449,
        rodmenys1red: 0,
        rodmenys2red: 0,
        skirtumasred: 0,
        butasviso: null
    
    },
  ], 
  rezultatas: [
      {
        periodo_data:"",
        namo_ID:null,
        adresas_gatve:"",
        adresas_namo_nr:""
      }]
  
      
};
export const dataReducer = (state = initialState, action):initialStateType => {
  // let stateCopy;
  switch (action.type) {
    case set_data: {
      // debugger;
      return { ...state, ...action.userdata };
    }
    case set_menesine_data: {
      return { ...state, menesine:action.data };
    }
    case set_menesine_raw_data: {
      return { ...state, menesineraw:action.data };
    }
    case set_menesineKV: {
      return { ...state, menesineKV:action.data };
    }
    case set_menesineSD: {
      return { ...state, menesineSD:action.data };
    }    
    default:
      return state;
  }
}

// export default dataReducer;
// Action creators------------------------------------------------------------------------------------------------
type userdataType = {data:any};
type set_dataActionCreatorType = {
  type: typeof set_data,
  userdata:userdataType
};
type userdatamenesineType = any;
type set_menesine_dataActionCreatorType = {
  type: typeof set_menesine_data,
  data:userdatamenesineType
}
type set_menesine_raw_dataActionCreatorType = {
  type: typeof set_menesine_raw_data,
  data:userdatamenesineType
}

const set_dataActionCreator = (data:any):set_dataActionCreatorType => ({ type: set_data, userdata: { ...data} });
const set_menesineDataActionCreator = (data:any):set_menesine_dataActionCreatorType => ({ type: set_menesine_data, data: data });
const setMenesineRawDataActionCreator = (data:any):set_menesine_raw_dataActionCreatorType => ({ type: set_menesine_raw_data, data: data });
const setMenesineKVActionCreator = (data:any) => ({ type: set_menesineKV, data: data });
const setMenesineSDActionCreator = (data:any) => ({ type: set_menesineSD, data: data });
// const setcaptchaActionCreator = (data) => ({type: set_captcha, data});

// ---------------Thunks------------------------------------------------------------------------------------------
export const getDataThunkCreator = () => {
  // debugger;
  return async (dispatch) => {
    const response = await dataApi.getdata()
    if (response.status == 1) {
      // alert("Veikia Thunk");
      console.log(response);
      dispatch(set_dataActionCreator(response));
    } else { alert('Jūs neprisiloginęs ' + response.messages) };
  }
}
export const getMenesineThunkCreator = (data:any, setLoading) => {
  // alert("formData.email");
  // debugger;
  setLoading(true);
  return async (dispatch) => {
    const response = await dataApi.menesine(data)
    // debugger;
    // console.log(response);    
    // // alert(data.resultCode + "Veikia");       
    if (response.status == 1) {
      // alert(response.message+ "Gaidys");
      // debugger;

      dispatch(set_menesineDataActionCreator(response));
      setLoading(false);
// Reikia išjungti formą!!!!!!!!!!!!!!!!!!!

      // dispatch(authThunkCreator());
      // dispatch(setcaptchaActionCreator(null));
    } else if (response.status == 0) {
      // dispatch(getcaptchaThunkCreator());
      // alert(response.message);
    }
    else {
    //   let message = response.data.messages.length > 0 ? response.data.messages[0] : 'Kažkokia klaida';
    //   // alert(data.messages[1]);
    //   dispatch(stopSubmit("login", { _error: message }));// šitas metodas iš redux formos parodo formos klaidą jeigu tokia grąžinama iš serverio
    //   // alert('_error');
    // }
    // if (data.resultCode == 0) {
    // dispatch(set_user_dataActionCreator(data.data.id,data.data.login, data.data.email));
    //   }else {alert('Jūs neprisiloginęs')};      
  }
}
};
export const getMenesineRawThunkCreator = (data:any, setLoading) => {
  // alert("formData.email");
  // debugger;
  setLoading(true);
  return async (dispatch) => {
    const response = await dataApi.bandymas(data)
    // debugger;
    dispatch(set_dataActionCreator(response));
    console.log(response);    
    // alert(response.status + "Veikia");       
    if (response.status == 1) {      
       
      //==========================================================================
      //Visas skaičiavimas
        const rodmenysreda = (item, rodmenys): number => {return Math.round(item[rodmenys] * item["rad_galia"] * item["rad_medziaga"] * item["kt"] * item["kamb_padetis"])};
        const skirtumasred= (item): number =>{if(item["skaitiklio_marke"]==8){return (rodmenysreda(item, "rodmenys2")-rodmenysreda(item, "rodmenys1"))}else{return parseFloat((item.rodmenys2-item.rodmenys1).toFixed(3))}};
        const data1 = response.rezultatas.map(item => { return { ...item, key: item.skaitiklio_Nr, rodmenys1red:rodmenysreda(item, "rodmenys1"), rodmenys2red:rodmenysreda(item, "rodmenys2"), skirtumasred:skirtumasred(item), pradzios_data:response.pradzios_data, pabaigos_data:response.pabaigos_data} }); // Būtinai reikalingas unikalus key!!!!!!!!! 
        data1.map((item)=>{
          item.butasviso=0;
          if(item.kambarys===null){
            return item.kambarys = "Karšto vandens skaitiklis1";            
          }
        });
        //================================================== 
      //sort pagal raides 
      // data1.sort((a, b) => {
        //   const nameA = a.buto_Nr.toUpperCase(); // ignore upper and lowercase
        //   const nameB = b.buto_Nr.toUpperCase(); // ignore upper and lowercase
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        
        //   // names must be equal
        //   return 0;
        // });
        //==========================================================
        //sort pagal skaičius
        data1.sort((a, b) => a.buto_Nr - b.buto_Nr);
        //=======================================================
        // Pagal kelis laukelius
        data1.sort((p1, p2) => {
            // if (p1.buto_Nr < p2.buto_Nr) return -1;
            // if (p1.buto_Nr > p2.buto_Nr) return 1;
            if (p1.buto_Nr===p2.buto_Nr){
              if (p1.kambarys < p2.kambarys) return -1;
            if (p1.kambarys > p2.kambarys) return 1;
            return 0};
          }
        );
        //==============================================================
        //Pagal eilutę. Negali būti null langelių
          //   let byName = visas1.slice(0);
          // byName.sort(function(a,b) {
          //     var x = a.kambarys.toLowerCase();
          //     var y = b.kambarys.toLowerCase();
          //     return x < y ? -1 : x > y ? 1 : 0;
          // });

          // console.log('by name:');
          // console.log(byName);
        //==============================================================  
        
        const dataKV = data1.filter(item => { return item.skaitiklio_marke==6});
        const dataSD = data1.filter(item => { return item.skaitiklio_marke==8});

        // dataSD.map((item, index)=>{
        //   butasviso=0;
        //   if(item.kambarys===null){
        //     return item.kambarys = "Karšto vandens skaitiklis1";
            
        //   }
        // });
        //const [sanaudos, setSanaudos] = useState(0);
        let sanaudosViso = 0;
        dataSD.reduce(getSum, 0);

        function getSum(total, currentValue, currentIndex, dataSD) {
          let index = dataSD.map(function (e) {return e.buto_Nr;}).lastIndexOf(currentValue.buto_Nr);
          total += currentValue.skirtumasred;
          if(index===currentIndex){
            currentValue.butasviso=total;
            sanaudosViso += total;            
            total = 0;
          }else{
            currentValue.butasviso=null;     
          }
          return total;
        };
        //setSanaudos(sanaudosViso); // Neveikia. nes per daug renderių!!!!!!!!!!!!!!
        console.log(sanaudosViso);
       
        dispatch(setMenesineRawDataActionCreator(data1));
        dispatch(setMenesineKVActionCreator(dataKV));
        dispatch(setMenesineSDActionCreator(dataSD));
      //==========================================================================


      setLoading(false);
      // alert(response.message);
// Reikia išjungti formą!!!!!!!!!!!!!!!!!!!

      // dispatch(authThunkCreator());
      // dispatch(setcaptchaActionCreator(null));
    } else if (response.status == 0) {
      // dispatch(getcaptchaThunkCreator());
      alert(response.message);
    }
    else {
    //   let message = response.data.messages.length > 0 ? response.data.messages[0] : 'Kažkokia klaida';
    //   // alert(data.messages[1]);
    //   dispatch(stopSubmit("login", { _error: message }));// šitas metodas iš redux formos parodo formos klaidą jeigu tokia grąžinama iš serverio
    //   // alert('_error');
    // }
    // if (data.resultCode == 0) {
    // dispatch(set_user_dataActionCreator(data.data.id,data.data.login, data.data.email));
    //   }else {alert('Jūs neprisiloginęs')};      
  }
}
};
export const getExcelThunkCreator = (data) => {
  // alert("formData.email");
  // debugger;
  return async (dispatch) => {
    const response = await dataApi.getExcel(data)
    // debugger;
    console.log(response);
    // const data = {
    //   id: null,
    //   login: null,
    //   email: null,
    //   isAuth: false
    //   // captcha: null
    // }              
    // // alert(data.resultCode + "Veikia");       
    // if (response.status == 1) {
    //   // alert(response.message);
    //   // debugger;
    //   dispatch(set_user_dataActionCreator(response));
// Reikia išjungti formą!!!!!!!!!!!!!!!!!!!

      // dispatch(authThunkCreator());
      // dispatch(setcaptchaActionCreator(null));
  //   } else if (response.status == 0) {
  //     // dispatch(getcaptchaThunkCreator());
  //     // alert(response.message);
  //     dispatch(delete_user_dataActionCreator(data));
  //   }
  //   else {
  //   //   let message = response.data.messages.length > 0 ? response.data.messages[0] : 'Kažkokia klaida';
  //   //   // alert(data.messages[1]);
  //   //   dispatch(stopSubmit("login", { _error: message }));// šitas metodas iš redux formos parodo formos klaidą jeigu tokia grąžinama iš serverio
  //   //   // alert('_error');
  //   // }
  //   // if (data.resultCode == 0) {
  //   // dispatch(set_user_dataActionCreator(data.data.id,data.data.login, data.data.email));
  //   //   }else {alert('Jūs neprisiloginęs')};      
  // }
}
};
// export const deleteUserThunkCreator = () => {  
//   return async (dispatch) => {
//     const response = await userApi.deleteuser()
//     console.log(response); 
//     const data = {
//         id: null,
//         login: null,
//         email: null,
//         isAuth: false
//         // captcha: null
//       }          
//     if (response.status == 1) {
      
//       // debugger;
//       dispatch(delete_user_dataActionCreator(data));
//     } else if (response.status == 0) {     
//       // alert(response.message);
//       dispatch(delete_user_dataActionCreator(data));
//     }
//     else {
//       // alert(response.message);
//   }
// }
// };
// export const signinThunkCreator = (userdata) => {  
//   return async (dispatch) => {
//     const response = await userApi.signinuser(userdata)
//     console.log(response); 
//     const data = {
//         id: null,
//         login: null,
//         email: null,
//         isAuth: false
//         // captcha: null
//       }          
//     if (response.status == 1) {
//       alert(response.message);
//       // debugger;
//       // dispatch(delete_user_dataActionCreator(data));
//     } else if (response.status == 0) {     
//       alert(response.message);
//       // dispatch(delete_user_dataActionCreator(data));
//     }
//     else {
//       // alert(response.message);
//   }
// }
// };