import React from 'react';
import { Link, Outlet, Route, Routes, useNavigate} from "react-router-dom"
import {
  MenuFoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import { Button, Image, Layout, Menu, theme, Col, Row } from 'antd';
import { useState } from 'react';
import logo from '../images/animal.jpg';
import { Content } from 'antd/es/layout/layout';
import { Header1 } from './Header/Header1';
import { Blog } from './Blog';
import { Users } from './Users';

// import {Link, Routes, Route, } from 'react-router-dom';


export const About = (props) => {
  const navigate = useNavigate();
  const atgal = () => {navigate(-1)}

  return (
    <div>
     <h1>About</h1>
     <button onClick={atgal}>Atgal</button>
    </div>
  );
}