import { useEffect, useState, useRef } from "react";
// import useFetch from "react-fetch-hook";
import { useLocation, useSearchParams } from "react-router-dom";
import { getExcelThunkCreator, getMenesineThunkCreator, getMenesineRawThunkCreator } from "../../../redux/data_Reducer.ts";
import { connect } from 'react-redux';
import { Table, Image, Pagination, Typography, Space, Button } from "antd";
import {
  DownloadOutlined
} from '@ant-design/icons';


import * as XLSX from 'xlsx';
import { read, writeFileXLSX } from "xlsx";
import { WithNavigateAuth } from "../../../Hoc/WithAuthNavigate.js";
import { compose } from "redux";




const { Text } = Typography;

// let masyvas1 = [];
// const Menesine2 = (props) => {

//   // const menesine = props.menesineData;
//   // console.log(menesine);
//   const data1 = props.menesineData.rezultatas.map(item => { return { ...item, key: item.skaitiklio_Nr } }); // Būtinai reikalingas unikalus key!!!!!!!!! 
//   // masyvas1 = props.menesineData.map(item => { return item.uniqueUrlName }); // filtravimo sąrašui sudaryti reikalingas masyvas su reikšmėmis
//   const pagination = {
//     position: ['bottomLeft'],
//     defaultPageSize: '10',
//     showSizeChanger: true,
//     pageSizeOptions: [10, 20, 25, 50, 100, 150, 200]
//   };
//   // debugger;
//   const unique = (val1, index1, arr1) => { return arr1.findIndex(val2 => { return val2.buto_Nr == val1.buto_Nr }) === index1 };
//   const butoNrSort = data1.filter(unique);
//   // const a = butoNrSort.map(item => { return { text: item.buto_Nr, value: item.buto_Nr}});
//   // console.log(a);  
//   const columns = [
//     {
//       title: 'Buto numeris',
//       dataIndex: 'buto_Nr',
//       key: 'buto_Nr',
//       filterSearch: true,
//       filters:               //Formuojame filtravimo menu     
//         butoNrSort.map(item => { return { text: item.buto_Nr, value: item.buto_Nr } }),
//       filterMode: 'tree',
//       // onFilter: ((value, item) => item.buto_Nr.includes(value)), // Filtravimo funkcija, filtruoja pagal tai ar laukelyje yra tokia frazė, ne pagal visą laukelį
//       // onFilter: ((value, item) => item.buto_Nr.indexOf(value) === 0),  // pagal pirmą rastą laukelio dalies frazę
//       onFilter: ((value, item) => item.buto_Nr === value),   // pagal tai ar laukelis visiškai sutampa
//       // filterDropdownOpen: false,
//       // filteredValue:["1", "2"],   // nurodomas masyvas pagal ką filtruoti, bet tada neleidžia nustatyti filtro rankiniu būdu
//       // filterMultiple:true,
//       // sortDirections:["ascend", "descend"], // nežinau kam reikalinga, vieną išmetus neberodo sort ikonos rodyklės
//       sorter: (a, b) => a.buto_Nr - b.buto_Nr,
//       // sortOrder: "ascend", // statiškai nustatu rūšiavimo tvarką
//     },
//     {
//       title: 'Kambarys',
//       dataIndex: 'kambarys',
//       key: 'kambarys',
//     },
//     {
//       title: 'Skaitiklio Nr',
//       dataIndex: 'skaitiklio_Nr',
//       key: 'skaitiklio_Nr',
//     },
//     {
//       title: 'Periodo pradžia',
//       dataIndex: 'pradzia',
//       key: 'pradzia',
//     },
//     {
//       title: 'Laikas',
//       dataIndex: 'pradzia_laikas',
//       key: 'pradzia_laikas',
//     },
//     {
//       title: 'Periodo pabaiga',
//       dataIndex: 'pabaiga',
//       key: 'pabaiga',
//     },
//     {
//       title: 'Laikas',
//       dataIndex: 'pabaiga_laikas',
//       key: 'pabaiga_laikas',
//     },
//     {
//       title: 'Daviklio rodmenys',
//       dataIndex: 'rodmenys1',
//       key: 'rodmenys1',
//     },
//     {
//       title: 'Redaguoti rodmenys',
//       dataIndex: 'rodmenys1_red',
//       key: 'rodmenys1_red',
//     },
//     {
//       title: 'Daviklio rodmenys',
//       dataIndex: 'rodmenys2',
//       key: 'rodmenys2',
//     },
//     {
//       title: 'Redaguoti rodmenys',
//       dataIndex: 'rodmenys2_red',
//       key: 'rodmenys2_red',
//     },
//     {
//       title: 'Periodo sąnaudos',
//       dataIndex: 'sanaudos',
//       key: 'sanaudos',
//     },
//     {
//       title: 'Sąnaudos redaguotos',
//       dataIndex: 'sanaudos_redaguotos',
//       key: 'sanaudos_redaguotos',
//     },
//     {
//       title: 'Buto sąnaudos',
//       dataIndex: 'buto_sanaudos',
//       key: 'buto_sanaudos',
//     },
//   ];

//   return (
//     <>
//       <div className="App">
//         {/* <h1>{location.search?location.search:"Blog"}</h1> */}
//         <h1>Gaidys</h1>
//         <div>
//           <Table
//             loading={props.loading}
//             dataSource={data1}
//             columns={columns}
//             bordered={true}
//             pagination={pagination}
//             showHeader={true}
//             size={'small'}
//             title={() => { return "Namo šilumos daliklių rodmenys" }}

//             // Summary
//             summary={(data1) => {
//               let total = 0;
//               let totalR = 0;
//               data1.forEach(({ buto_sanaudos, sanaudos_redaguotos }) => {
//                 total += buto_sanaudos;
//                 totalR += sanaudos_redaguotos; // kitas parametras
//               });
//               return (
//                 <>
//                   <Table.Summary.Row align={"right"}>
//                     <Table.Summary.Cell index={0}>Viso sąnaudos</Table.Summary.Cell>
//                     <Table.Summary.Cell index={1} > <Text type="danger">{total}</Text> </Table.Summary.Cell>
//                     {/* <Table.Summary.Cell index={2}> <Text>{totalRepayment}</Text> </Table.Summary.Cell> */}
//                   </Table.Summary.Row>




//                   {/* <Table.Summary.Row>
//                           <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
//                           <Table.Summary.Cell index={1} colSpan={2}>
//                             <Text type="danger">{totalBorrow - totalRepayment}</Text>
//                           </Table.Summary.Cell>
//                         </Table.Summary.Row> */}
//                 </>
//               );
//             }}
//           />
//           <Space wrap>
//             <Button type="primary" icon={<DownloadOutlined />} onClick={() => props.onSubmit()}>Atsiiųsti Exel failą</Button>
//           </Space>
//         </div>
//       </div>
//     </>
//   )
// }
const Menesine2 = (props) => {
  const myTable = useRef(null);
  // const menesine = props.menesineData;
  // console.log(menesine);
  //   const rodmenysreda = (item, rodmenys) => {return Math.round(item[rodmenys] * item["rad_galia"] * item["rad_medziaga"] * item["kt"] * item["kamb_padetis"])};
  //   const skirtumasred = (item)=>{if(item["skaitiklio_marke"]==8){return (rodmenysreda(item, "rodmenys2")-rodmenysreda(item, "rodmenys1"))}else{return (item.rodmenys2-item.rodmenys1).toFixed(3)}};
  //   const data1 = props.menesineData.menesineraw.rezultatas.map(item => { return { ...item, key: item.skaitiklio_Nr, rodmenys1red:rodmenysreda(item, "rodmenys1"), rodmenys2red:rodmenysreda(item, "rodmenys2"), skirtumasred:skirtumasred(item)} }); // Būtinai reikalingas unikalus key!!!!!!!!! 
  //   data1.map((item)=>{
  //     item.butasviso=0;
  //     if(item.kambarys===null){
  //       return item.kambarys = "Karšto vandens skaitiklis1";

  //     }
  //   });
  //   //================================================== 
  //  //sort pagal raides 
  //  // data1.sort((a, b) => {
  //   //   const nameA = a.buto_Nr.toUpperCase(); // ignore upper and lowercase
  //   //   const nameB = b.buto_Nr.toUpperCase(); // ignore upper and lowercase
  //   //   if (nameA < nameB) {
  //   //     return -1;
  //   //   }
  //   //   if (nameA > nameB) {
  //   //     return 1;
  //   //   }

  //   //   // names must be equal
  //   //   return 0;
  //   // });
  //   //==========================================================
  //   //sort pagal skaičius
  //   data1.sort((a, b) => a.buto_Nr - b.buto_Nr);
  //   //=======================================================
  //   // Pagal kelis laukelius
  //   data1.sort((p1, p2) => {
  //       // if (p1.buto_Nr < p2.buto_Nr) return -1;
  //       // if (p1.buto_Nr > p2.buto_Nr) return 1;
  //       if (p1.buto_Nr===p2.buto_Nr){
  //         if (p1.kambarys < p2.kambarys) return -1;
  //       if (p1.kambarys > p2.kambarys) return 1;
  //       return 0};
  //     }
  //   );
  //   //==============================================================
  //   //Pagal eilutę. Negali būti null langelių
  //     //   let byName = visas1.slice(0);
  //     // byName.sort(function(a,b) {
  //     //     var x = a.kambarys.toLowerCase();
  //     //     var y = b.kambarys.toLowerCase();
  //     //     return x < y ? -1 : x > y ? 1 : 0;
  //     // });

  //     // console.log('by name:');
  //     // console.log(byName);
  //   //==============================================================  

  //   const dataKV = data1.filter(item => { return item.skaitiklio_marke==6});
  //   const dataSD = data1.filter(item => { return item.skaitiklio_marke==8});



  //   // dataSD.map((item, index)=>{
  //   //   butasviso=0;
  //   //   if(item.kambarys===null){
  //   //     return item.kambarys = "Karšto vandens skaitiklis1";

  //   //   }
  //   // });
  //   const [sanaudos, setSanaudos] = useState(0);
  //   let sanaudosViso = 0;
  //   dataSD.reduce(getSum, 0);

  //   function getSum(total, currentValue, currentIndex, dataSD) {
  //     let index = dataSD.map(function (e) {return e.buto_Nr;}).lastIndexOf(currentValue.buto_Nr);
  //     total += currentValue.skirtumasred;
  //     if(index===currentIndex){
  //       currentValue.butasviso=total;
  //       sanaudosViso += total;

  //       total = 0;
  //     }else{
  //       currentValue.butasviso=null;     
  //     }
  //     return total;
  //   };
  //   //setSanaudos(sanaudosViso); // Neveikia. nes per daug renderių!!!!!!!!!!!!!!
  //   console.log(sanaudosViso);





  //   console.log(data1);
  //   console.log(dataKV);
  //   console.log(dataSD);
  // let masyvas1 = props.menesineData.rezultatas.map(item => { return item.uniqueUrlName }); // filtravimo sąrašui sudaryti reikalingas masyvas su reikšmėmis
  // console.log(masyvas1);
  const pagination = {
    position: ['bottomLeft'],
    defaultPageSize: '10',
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 25, 50, 100, 150, 200]
  };
  let data1 = props.menesineData.menesineraw;
  // let data2 = props.menesineData.menesineKV;
  console.log(data1);
  const unique = (val1, index1, arr1) => { return arr1.findIndex(val2 => { return val2.buto_Nr == val1.buto_Nr }) === index1 };
  const butoNrSort = data1.filter(unique);
  const columnsRAW = [
    {
      title: 'Buto numeris',
      dataIndex: 'buto_Nr',
      key: 'buto_Nr',
      filterSearch: true,
      filters:               //Formuojame filtravimo menu     
        butoNrSort.map(item => { return { text: item.buto_Nr, value: item.buto_Nr } }),
      filterMode: 'tree',
      // onFilter: ((value, item) => item.buto_Nr.includes(value)), // Filtravimo funkcija, filtruoja pagal tai ar laukelyje yra tokia frazė, ne pagal visą laukelį
      // onFilter: ((value, item) => item.buto_Nr.indexOf(value) === 0),  // pagal pirmą rastą laukelio dalies frazę
      onFilter: ((value, item) => item.buto_Nr === value),   // pagal tai ar laukelis visiškai sutampa
      // filterDropdownOpen: false,
      // filteredValue:["1", "2"],   // nurodomas masyvas pagal ką filtruoti, bet tada neleidžia nustatyti filtro rankiniu būdu
      // filterMultiple:true,
      // sortDirections:["ascend", "descend"], // nežinau kam reikalinga, vieną išmetus neberodo sort ikonos rodyklės
      sorter: (a, b) => a.buto_Nr - b.buto_Nr,
      // sortOrder: "ascend", // statiškai nustatu rūšiavimo tvarką
    },
    {
      title: 'Kambarys',
      dataIndex: 'kambarys',
      key: 'kambarys',
    },
    {
      title: 'Skaitiklio markė',
      dataIndex: 'skaitiklio_marke',
      key: 'skaitiklio_marke',
    },
    {
      title: 'Skaitiklio Nr',
      dataIndex: 'skaitiklio_Nr',
      key: 'skaitiklio_Nr',
    },
    {
      title: 'Periodo pradžia',
      dataIndex: 'pradzia',
      key: 'pradzia',
    },
    {
      title: 'Laikas',
      dataIndex: 'pradzia_laikas',
      key: 'pradzia_laikas',
    },
    {
      title: 'Daviklio rodmenys',
      dataIndex: 'rodmenys1',
      key: 'rodmenys1',
    },
    {
      title: 'Periodo pabaiga',
      dataIndex: 'pabaiga',
      key: 'pabaiga',
    },
    {
      title: 'Laikas',
      dataIndex: 'pabaiga_laikas',
      key: 'pabaiga_laikas',
    },
    {
      title: 'Daviklio rodmenys',
      dataIndex: 'rodmenys2',
      key: 'rodmenys2',
    },
  ];
  const columnsKV = [
    {
      title: 'Adresas',
      dataIndex: 'pilnas_adresas',
      key: 'adresas_gatve',
      render: (text, record) => (
        <span>{record.adresas_gatve} {record.adresas_namo_nr}</span>
      )
    },
    {
      title: 'Buto numeris',
      dataIndex: 'buto_Nr',
      key: 'buto_Nr',
      filterSearch: true,
      filters:               //Formuojame filtravimo menu     
        butoNrSort.map(item => { return { text: item.buto_Nr, value: item.buto_Nr } }),
      filterMode: 'tree',
      // onFilter: ((value, item) => item.buto_Nr.includes(value)), // Filtravimo funkcija, filtruoja pagal tai ar laukelyje yra tokia frazė, ne pagal visą laukelį
      // onFilter: ((value, item) => item.buto_Nr.indexOf(value) === 0),  // pagal pirmą rastą laukelio dalies frazę
      onFilter: ((value, item) => item.buto_Nr === value),   // pagal tai ar laukelis visiškai sutampa
      // filterDropdownOpen: false,
      // filteredValue:["1", "2"],   // nurodomas masyvas pagal ką filtruoti, bet tada neleidžia nustatyti filtro rankiniu būdu
      // filterMultiple:true,
      // sortDirections:["ascend", "descend"], // nežinau kam reikalinga, vieną išmetus neberodo sort ikonos rodyklės
      sorter: (a, b) => a.buto_Nr - b.buto_Nr,
      // sortOrder: "ascend", // statiškai nustatu rūšiavimo tvarką
    },
    // {
    //   title: 'Kambarys',
    //   dataIndex: 'kambarys',
    //   key: 'kambarys',
    // },
    // {
    //   title: 'Skaitiklio markė',
    //   dataIndex: 'skaitiklio_marke',
    //   key: 'skaitiklio_marke',
    // },
    {
      title: 'Skaitiklio Nr',
      dataIndex: 'skaitiklio_Nr',
      key: 'skaitiklio_Nr',
    },
    // {
    //   title: 'Periodo pradžia',
    //   dataIndex: 'pradzia',
    //   key: 'pradzia',
    // },
    // {
    //   title: 'Laikas',
    //   dataIndex: 'pradzia_laikas',
    //   key: 'pradzia_laikas',
    // },
    {
      title: 'Nuo',
      dataIndex: 'rodmenys1',
      key: 'rodmenys1',
    },
    // {
    //   title: 'Periodo pabaiga',
    //   dataIndex: 'pabaiga',
    //   key: 'pabaiga',
    // },
    // {
    //   title: 'Laikas',
    //   dataIndex: 'pabaiga_laikas',
    //   key: 'pabaiga_laikas',
    // },    
    {
      title: 'Iki',
      dataIndex: 'rodmenys2',
      key: 'rodmenys2',
    },
    {
      title: 'Sunaudojimas',
      dataIndex: 'skirtumasred',
      key: 'skirtumasred',
    },
    {
      title: 'Duomenų nuskaitymo data',
      dataIndex: 'pabaigos_data',
      key: 'pabaigos_data'
    },

  ];
  // {
  //   title: "RADIATORIŲ SUNAUDOTOS ŠILUMOS SUVESTINĖ",
  //   children:
  //     [
  //       {
  //         title: "Kviečių g. 22",
  //         children:
  //           [
  //             {
  //               title: "Einamas periodas",
  //             },
  //             {
  //               title: "Einamas periodas2"
  //             },
  //             {
  //               children:
  //                 [
  //                   {
  //                     title: "Reikšmės perskaičiuotos",

  //                   },
  //                 ],
  //             },
  //           ],

  //       },
  //     ],
  // },
  const columnsSD =
    [
      {
        title: 'Buto numeris',
        dataIndex: 'buto_Nr',
        key: 'buto_Nr',
        filterSearch: true,
        filters:               //Formuojame filtravimo menu     
          butoNrSort.map(item => { return { text: item.buto_Nr, value: item.buto_Nr } }),
        filterMode: 'tree',
        // onFilter: ((value, item) => item.buto_Nr.includes(value)), // Filtravimo funkcija, filtruoja pagal tai ar laukelyje yra tokia frazė, ne pagal visą laukelį
        // onFilter: ((value, item) => item.buto_Nr.indexOf(value) === 0),  // pagal pirmą rastą laukelio dalies frazę
        onFilter: ((value, item) => item.buto_Nr === value),   // pagal tai ar laukelis visiškai sutampa
        // filterDropdownOpen: false,
        // filteredValue:["1", "2"],   // nurodomas masyvas pagal ką filtruoti, bet tada neleidžia nustatyti filtro rankiniu būdu
        // filterMultiple:true,
        // sortDirections:["ascend", "descend"], // nežinau kam reikalinga, vieną išmetus neberodo sort ikonos rodyklės
        sorter: (a, b) => a.buto_Nr - b.buto_Nr,
        // sortOrder: "ascend", // statiškai nustatu rūšiavimo tvarką
      },
      {
        title: 'Skaitiklio Nr',
        dataIndex: 'skaitiklio_Nr',
        key: 'skaitiklio_Nr',
      },
      {
        title: 'Kambarys',
        dataIndex: 'kambarys',
        key: 'kambarys',
      },
      // {
      //   title: 'Skaitiklio markė',
      //   dataIndex: 'skaitiklio_marke',
      //   key: 'skaitiklio_marke',
      // },   
      {
        title: 'Periodo pradžia',
        dataIndex: 'pradzia',
        key: 'pradzia',
      },
      // {
      //   title: 'Laikas',
      //   dataIndex: 'pradzia_laikas',
      //   key: 'pradzia_laikas',
      // },
      {
        title: 'Daviklio rodmenys',
        dataIndex: 'rodmenys1red',
        key: 'rodmenys1red',
      },
      {
        title: 'Periodo pabaiga',
        dataIndex: 'pabaiga',
        key: 'pabaiga',
      },
      // {
      //   title: 'Laikas',
      //   dataIndex: 'pabaiga_laikas',
      //   key: 'pabaiga_laikas',
      // },    
      {
        title: 'Daviklio rodmenys',
        dataIndex: 'rodmenys2red',
        key: 'rodmenys2red',
      },
      {
        title: 'Skirtumas',
        dataIndex: 'skirtumasred',
        key: 'skirtumasred',
      },
      {
        title: 'Viso už butą',
        dataIndex: 'butasviso',
        key: 'butasviso',
      },
    ];

  async function handleFileAsync(e, tabeller, startCell) {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const wb = XLSX.read(data);
    console.log(wb);
    return;
    const table = document.getElementById(tabeller).querySelector('table');
    var ws = wb.Sheets["Lapas1"];
    // alert("Veikia");

    // console.log("ws");
    XLSX.utils.sheet_add_dom(ws, table, { origin: startCell });
    // const ws = XLSX.utils.table_to_sheet(table);
    // console.log(ws);    
    // XLSX.utils.book_append_sheet(wb, ws, "KitasFailas", true);
    // console.log(XLSX);    
    const fileName = props.menesineData.menesineKV[0].adresas_gatve + " g. " + props.menesineData.menesineKV[0].adresas_namo_nr + ".xlsx"
    XLSX.writeFile(wb, fileName);
  }

  return (
    <>
      <div className="App">
        {/* <h1>{location.search?location.search:"Blog"}</h1> */}
        <h1>Ataskaitos neapdoroti duomenys</h1>
        <div>
          <Table
            id={'tabeller1'}
            loading={props.loading}
            dataSource={props.menesineData.menesineraw}
            columns={columnsRAW}
            bordered={true}
            pagination={pagination}
            showHeader={true}
            size={'small'}
            title={() => { return "Namo šilumos daliklių rodmenys" }}

          // Summary
          // summary={(data1) => {
          //   let total = 0;
          //   let totalR = 0;
          //   data1.forEach(({ buto_sanaudos, sanaudos_redaguotos }) => {
          //     total += buto_sanaudos;
          //     totalR += sanaudos_redaguotos; // kitas parametras
          //   });
          //   return (
          //     <>
          //       <Table.Summary.Row align={"right"}>
          //         <Table.Summary.Cell index={0}>Viso sąnaudos</Table.Summary.Cell>
          //         <Table.Summary.Cell index={1} > <Text type="danger">{total}</Text> </Table.Summary.Cell>
          //         {/* <Table.Summary.Cell index={2}> <Text>{totalRepayment}</Text> </Table.Summary.Cell> */}
          //       </Table.Summary.Row>




          //       {/* <Table.Summary.Row>
          //               <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
          //               <Table.Summary.Cell index={1} colSpan={2}>
          //                 <Text type="danger">{totalBorrow - totalRepayment}</Text>
          //               </Table.Summary.Cell>
          //             </Table.Summary.Row> */}
          //     </>
          //   );
          // }}
          />
          <Space wrap>
            <Button type="primary" icon={<DownloadOutlined />} onClick={
              () => {

                /* Create worksheet from HTML DOM TABLE */
                const table = document.getElementById('tabeller1').querySelector('table');

                // debugger;
                const wb = XLSX.utils.table_to_book(table);

                /* Export to file (start a download) */
                XLSX.writeFile(wb, "SheetJSIntro.xlsx");

              }
            }>Atsiiųsti Exel failą</Button>
          </Space>
        </div>
      </div>
      <div className="App">
        {/* <h1>{location.search?location.search:"Blog"}</h1> */}
        <h1>Karšto vandens skaitiklių ataskaita</h1>
        <div>
          <Table
            // loading={props.loading}
            id={'tabeller2'}
            dataSource={props.menesineData.menesineKV}
            columns={columnsKV}
            bordered={true}
            pagination={pagination}
            showHeader={true}
            size={'small'}
            title={() => { return "Namo šilumos daliklių rodmenys" }}
            nuskaitymoData={props.menesineData.pabaigos_data}

            Summary
            summary={(data2) => {

              // let total = 0;
              // data2.forEach(({ skirtumasred }) => {
              //   total += skirtumasred;
              // });

              let total = props.menesineData.menesineKV.reduce((total, { skirtumasred }) => {
                total += skirtumasred;
                return total;
              }, 0);

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={5} align={"right"}>Viso sąnaudos</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align={"left"}> <Text type="danger">{parseFloat(total.toFixed(3))}</Text> </Table.Summary.Cell>
                    {/* <Table.Summary.Cell index={2}> <Text>{totalRepayment}</Text> </Table.Summary.Cell> */}
                  </Table.Summary.Row>




                  {/* <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2}>
                          <Text type="danger">{totalBorrow - totalRepayment}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row> */}
                </>
              );
            }}
          />
          <Space wrap>
            <Button type="primary" icon={<DownloadOutlined />} onClick={
              () => {

                /* Create worksheet from HTML DOM TABLE */
                const table = document.getElementById('tabeller2').querySelector('table');

                // debugger;
                const wb = XLSX.utils.table_to_book(table);

                /* Export to file (start a download) */
                XLSX.writeFile(wb, "SheetJSIntro.xlsx");

              }
            }>Atsiiųsti Exel failą</Button>

            {/* //=============================BANDYMAS */}

            <Button type="primary" icon={<DownloadOutlined />} onClick={
              () => {
                const adresas = props.menesineData.menesineKV[0].adresas_gatve + " g. " + props.menesineData.menesineKV[0].adresas_namo_nr;
                const fileName = props.menesineData.menesineKV[0].adresas_gatve + " g. " + props.menesineData.menesineKV[0].adresas_namo_nr + ".xlsx"

                //const pradzios_data = props.menesineData.pradzios_data;
                const pabaigos_data = props.menesineData.pabaigos_data;
                const date = new Date(pabaigos_data);

                const metai = date.getFullYear();
                const menuo = date.getMonth();
                const menuoArr = ["sausio", "vasario", "kovo", "balandžio", "gegužės", "birželio", "liepos", "rugpjūčio", "rugsėjo", "spalio", "lapkričio", "gruodžio"];
                const menuoToString = menuoArr[menuo];               

                let wb = {
                  "Directory": {
                    "workbooks": [
                      "/xl/workbook.xml"
                    ],
                    "sheets": [
                      "/xl/worksheets/sheet1.xml"
                    ],
                    "charts": [],
                    "dialogs": [],
                    "macros": [],
                    "rels": [],
                    "strs": [
                      "/xl/sharedStrings.xml"
                    ],
                    "comments": [],
                    "threadedcomments": [],
                    "links": [],
                    "coreprops": [
                      "/docProps/core.xml"
                    ],
                    "extprops": [
                      "/docProps/app.xml"
                    ],
                    "custprops": [],
                    "themes": [
                      "/xl/theme/theme1.xml"
                    ],
                    "styles": [
                      "/xl/styles.xml"
                    ],
                    "vba": [],
                    "drawings": [],
                    "metadata": [],
                    "people": [],
                    "TODO": [],
                    "xmlns": "http://schemas.openxmlformats.org/package/2006/content-types",
                    "calcchain": "",
                    "sst": "/xl/sharedStrings.xml",
                    "style": "/xl/styles.xml",
                    "defaults": {
                      "rels": "application/vnd.openxmlformats-package.relationships+xml",
                      "xml": "application/xml"
                    }
                  },
                  "Workbook": {
                    "AppVersion": {
                      "appName": "xl",
                      "appname": "xl",
                      "lastEdited": "7",
                      "lastedited": "7",
                      "lowestEdited": "7",
                      "lowestedited": "7",
                      "rupBuild": "26924",
                      "rupbuild": "26924"
                    },
                    "WBProps": {
                      "defaultThemeVersion": 166925,
                      "allowRefreshQuery": false,
                      "autoCompressPictures": true,
                      "backupFile": false,
                      "checkCompatibility": false,
                      "CodeName": "",
                      "date1904": false,
                      "filterPrivacy": false,
                      "hidePivotFieldList": false,
                      "promptedSolutions": false,
                      "publishItems": false,
                      "refreshAllConnections": false,
                      "saveExternalLinkValues": true,
                      "showBorderUnselectedTables": true,
                      "showInkAnnotation": true,
                      "showObjects": "all",
                      "showPivotChartFilter": false,
                      "updateLinks": "userSet"
                    },
                    "WBView": [
                      {
                        "xWindow": "405",
                        "xwindow": "405",
                        "yWindow": "1365",
                        "ywindow": "1365",
                        "windowWidth": "22320",
                        "windowwidth": "22320",
                        "windowHeight": "17865",
                        "windowheight": "17865",
                        "uid": "{FFD1E51D-B351-4416-A875-019FDB7E4801}",
                        "activeTab": 0,
                        "autoFilterDateGrouping": true,
                        "firstSheet": 0,
                        "minimized": false,
                        "showHorizontalScroll": true,
                        "showSheetTabs": true,
                        "showVerticalScroll": true,
                        "tabRatio": 600,
                        "visibility": "visible"
                      }
                    ],
                    "Sheets": [
                      {
                        "name": "Lapas1",
                        "sheetId": "1",
                        "sheetid": "1",
                        "id": "rId1",
                        "Hidden": 0
                      }
                    ],
                    "CalcPr": {
                      "calcId": "191029",
                      "calcid": "191029",
                      "calcCompleted": "true",
                      "calcMode": "auto",
                      "calcOnSave": "true",
                      "concurrentCalc": "true",
                      "fullCalcOnLoad": "false",
                      "fullPrecision": "true",
                      "iterate": "false",
                      "iterateCount": "100",
                      "iterateDelta": "0.001",
                      "refMode": "A1"
                    },
                    "Names": [],
                    "xmlns": "http://schemas.openxmlformats.org/spreadsheetml/2006/main",
                    "Views": [
                      {}
                    ]
                  },
                  "Props": {
                    "LastAuthor": "Rolandas Macius",
                    "Author": "Ernesta Narbutienė",
                    "CreatedDate": "2023-08-17T07:28:15.000Z",
                    "ModifiedDate": "2023-11-02T20:14:42.000Z",
                    "Application": "Microsoft Excel",
                    "AppVersion": "16.0300",
                    "DocSecurity": "0",
                    "HyperlinksChanged": false,
                    "SharedDoc": false,
                    "LinksUpToDate": false,
                    "ScaleCrop": false,
                    "Worksheets": 1,
                    "SheetNames": [
                      "Lapas1"
                    ]
                  },
                  "Custprops": {},
                  "Deps": {},
                  "Sheets": {
                    "Lapas1": {
                      "!ref": "A2:L26",
                      "A2": {
                        "t": "s",
                        "v": "Gyvenamojo namo " + adresas,
                        "r": "<t>Gyvenamojo namo Kviečių g. 22</t>",
                        "h": "Gyvenamojo namo Kviečių g. 22",
                        "w": "Gyvenamojo namo Kviečių g. 22"
                      },
                      "A3": {
                        "t": "s",
                        "v": "Karšto vandens skaitiklių parodymų suvestinė už " + metai + " m. " + menuoToString + " mėn.",
                        "r": "<t>Karšto vandens skaitiklių parodymų suvestinė už 2023 m. rugpjūčio mėn.</t>",
                        "h": "Karšto vandens skaitiklių parodymų suvestinė už 2023 m. rugpjūčio mėn.",
                        "w": "Karšto vandens skaitiklių parodymų suvestinė už 2023 m. rugpjūčio mėn."
                      },
                      "A5": {
                        "t": "s",
                        "v": "Adresas",
                        "r": "<t>Adresas</t>",
                        "h": "Adresas",
                        "w": "Adresas"
                      },
                      "B5": {
                        "t": "s",
                        "v": "Buto Nr.",
                        "r": "<t>Buto Nr.</t>",
                        "h": "Buto Nr.",
                        "w": "Buto Nr."
                      },
                      "C5": {
                        "t": "s",
                        "v": "Skaitiklio numeris",
                        "r": "<t>Skaitiklio numeris</t>",
                        "h": "Skaitiklio numeris",
                        "w": "Skaitiklio numeris"
                      },
                      "D5": {
                        "t": "s",
                        "v": "Rodmuo",
                        "r": "<t>Rodmuo</t>",
                        "h": "Rodmuo",
                        "w": "Rodmuo"
                      },
                      "F5": {
                        "t": "s",
                        "v": "Skirtumas",
                        "r": "<t>Skirtumas</t>",
                        "h": "Skirtumas",
                        "w": "Skirtumas"
                      },
                      "G5": {
                        "t": "s",
                        "v": "Duomenų nuskaitymo data",
                        "r": "<t>Duomenų nuskaitymo data</t>",
                        "h": "Duomenų nuskaitymo data",
                        "w": "Duomenų nuskaitymo data"
                      },
                      "D6": {
                        "t": "s",
                        "v": "Nuo",
                        "r": "<t>Nuo</t>",
                        "h": "Nuo",
                        "w": "Nuo"
                      },
                      "E6": {
                        "t": "s",
                        "v": "Iki",
                        "r": "<t>Iki</t>",
                        "h": "Iki",
                        "w": "Iki"
                      },
                      "!margins": {
                        "left": 0.7,
                        "right": 0.7,
                        "top": 0.75,
                        "bottom": 0.75,
                        "header": 0.3,
                        "footer": 0.3
                      },
                      "!merges": [
                        {
                          "s": {
                            "c": 0,
                            "r": 1
                          },
                          "e": {
                            "c": 6,
                            "r": 1
                          }
                        },
                        {
                          "s": {
                            "c": 0,
                            "r": 2
                          },
                          "e": {
                            "c": 6,
                            "r": 2
                          }
                        },
                        {
                          "s": {
                            "c": 0,
                            "r": 4
                          },
                          "e": {
                            "c": 0,
                            "r": 5
                          }
                        },
                        {
                          "s": {
                            "c": 2,
                            "r": 4
                          },
                          "e": {
                            "c": 2,
                            "r": 5
                          }
                        },
                        {
                          "s": {
                            "c": 6,
                            "r": 4
                          },
                          "e": {
                            "c": 6,
                            "r": 5
                          }
                        },
                        {
                          "s": {
                            "c": 3,
                            "r": 4
                          },
                          "e": {
                            "c": 4,
                            "r": 4
                          }
                        },
                        {
                          "s": {
                            "c": 5,
                            "r": 4
                          },
                          "e": {
                            "c": 5,
                            "r": 5
                          }
                        },
                        {
                          "s": {
                            "c": 1,
                            "r": 4
                          },
                          "e": {
                            "c": 1,
                            "r": 5
                          }
                        }
                      ]
                    }
                  },
                  "SheetNames": [
                    "Lapas1"
                  ],
                  "Strings": [
                    {
                      "t": "Rodmuo",
                      "r": "<t>Rodmuo</t>",
                      "h": "Rodmuo"
                    },
                    {
                      "t": "Gyvenamojo namo Kviečių g. 22",
                      "r": "<t>Gyvenamojo namo Kviečių g. 22</t>",
                      "h": "Gyvenamojo namo Kviečių g. 22"
                    },
                    {
                      "t": "Karšto vandens skaitiklių parodymų suvestinė už 2023 m. rugpjūčio mėn.",
                      "r": "<t>Karšto vandens skaitiklių parodymų suvestinė už 2023 m. rugpjūčio mėn.</t>",
                      "h": "Karšto vandens skaitiklių parodymų suvestinė už 2023 m. rugpjūčio mėn."
                    },
                    {
                      "t": "Adresas",
                      "r": "<t>Adresas</t>",
                      "h": "Adresas"
                    },
                    {
                      "t": "Buto Nr.",
                      "r": "<t>Buto Nr.</t>",
                      "h": "Buto Nr."
                    },
                    {
                      "t": "Skaitiklio numeris",
                      "r": "<t>Skaitiklio numeris</t>",
                      "h": "Skaitiklio numeris"
                    },
                    {
                      "t": "Skirtumas",
                      "r": "<t>Skirtumas</t>",
                      "h": "Skirtumas"
                    },
                    {
                      "t": "Duomenų nuskaitymo data",
                      "r": "<t>Duomenų nuskaitymo data</t>",
                      "h": "Duomenų nuskaitymo data"
                    },
                    {
                      "t": "Nuo",
                      "r": "<t>Nuo</t>",
                      "h": "Nuo"
                    },
                    {
                      "t": "Iki",
                      "r": "<t>Iki</t>",
                      "h": "Iki"
                    },
                    {
                      "t": ""
                    }
                  ],
                  "Styles": {
                    "Fonts": [
                      {
                        "sz": 11,
                        "color": {
                          "theme": 1
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 8,
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "rgb": "4FC1FF"
                        },
                        "name": "Consolas",
                        "family": 3
                      }
                    ],
                    "Fills": [
                      {
                        "patternType": "none"
                      },
                      {
                        "patternType": "gray125"
                      }
                    ],
                    "Borders": [
                      {},
                      {},
                      {},
                      {},
                      {},
                      {}
                    ],
                    "CellXf": [
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 0,
                        "borderid": "0",
                        "xfId": 0,
                        "xfid": "0"
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 1,
                        "borderid": "1",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1",
                        "applyAlignment": true,
                        "applyalignment": "1",
                        "alignment": {
                          "horizontal": "center"
                        }
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 1,
                        "borderid": "1",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1"
                      },
                      {
                        "numFmtId": 14,
                        "numfmtid": "14",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 1,
                        "borderid": "1",
                        "xfId": 0,
                        "xfid": "0",
                        "applyNumberFormat": true,
                        "applynumberformat": "1",
                        "applyBorder": true,
                        "applyborder": "1"
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 2,
                        "borderid": "2",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1"
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 3,
                        "borderid": "3",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1"
                      },
                      {
                        "numFmtId": 14,
                        "numfmtid": "14",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 4,
                        "borderid": "4",
                        "xfId": 0,
                        "xfid": "0",
                        "applyNumberFormat": true,
                        "applynumberformat": "1",
                        "applyBorder": true,
                        "applyborder": "1"
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 2,
                        "fontid": "2",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 0,
                        "borderid": "0",
                        "xfId": 0,
                        "xfid": "0",
                        "applyFont": true,
                        "applyfont": "1",
                        "applyAlignment": true,
                        "applyalignment": "1",
                        "alignment": {
                          "vertical": "center"
                        }
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 0,
                        "borderid": "0",
                        "xfId": 0,
                        "xfid": "0"
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 1,
                        "borderid": "1",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1",
                        "applyAlignment": true,
                        "applyalignment": "1",
                        "alignment": {
                          "vertical": "center",
                          "horizontal": "center"
                        }
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 1,
                        "borderid": "1",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1",
                        "applyAlignment": true,
                        "applyalignment": "1",
                        "alignment": {
                          "vertical": "center",
                          "horizontal": "center",
                          "wrapText": true
                        }
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 1,
                        "borderid": "1",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1",
                        "applyAlignment": true,
                        "applyalignment": "1",
                        "alignment": {
                          "horizontal": "center",
                          "wrapText": true
                        }
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 1,
                        "borderid": "1",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1",
                        "applyAlignment": true,
                        "applyalignment": "1",
                        "alignment": {
                          "horizontal": "center"
                        }
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 2,
                        "borderid": "2",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1",
                        "applyAlignment": true,
                        "applyalignment": "1",
                        "alignment": {
                          "vertical": "center",
                          "horizontal": "center"
                        }
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 5,
                        "borderid": "5",
                        "xfId": 0,
                        "xfid": "0",
                        "applyBorder": true,
                        "applyborder": "1",
                        "applyAlignment": true,
                        "applyalignment": "1",
                        "alignment": {
                          "vertical": "center",
                          "horizontal": "center"
                        }
                      }
                    ]
                  },
                  "Themes": {},
                  "SSF": {
                    "0": "General",
                    "1": "0",
                    "2": "0.00",
                    "3": "#,##0",
                    "4": "#,##0.00",
                    "9": "0%",
                    "10": "0.00%",
                    "11": "0.00E+00",
                    "12": "# ?/?",
                    "13": "# ??/??",
                    "14": "m/d/yy",
                    "15": "d-mmm-yy",
                    "16": "d-mmm",
                    "17": "mmm-yy",
                    "18": "h:mm AM/PM",
                    "19": "h:mm:ss AM/PM",
                    "20": "h:mm",
                    "21": "h:mm:ss",
                    "22": "m/d/yy h:mm",
                    "37": "#,##0 ;(#,##0)",
                    "38": "#,##0 ;[Red](#,##0)",
                    "39": "#,##0.00;(#,##0.00)",
                    "40": "#,##0.00;[Red](#,##0.00)",
                    "45": "mm:ss",
                    "46": "[h]:mm:ss",
                    "47": "mmss.0",
                    "48": "##0.0E+0",
                    "49": "@",
                    "56": "\"上午/下午 \"hh\"時\"mm\"分\"ss\"秒 \""
                  },
                  "bookType": "xlsx"
                };
                // const file = e.target.files[0];
                // const data = await file.arrayBuffer();
                // const wb = XLSX.read(data);
                // console.log(wb);
                const table = document.getElementById('tabeller2').querySelector('table');
                const ws = wb.Sheets["Lapas1"];
                XLSX.utils.sheet_add_dom(ws, table, { origin: "A6" });
                // alert("Veikia");
                XLSX.writeFile(wb, fileName);
              }
            }>Atsiiųsti Exel failą su šablonu</Button>

            <label for="input_dom_element">Pasirinkite failo šabloną</label>
            <input type="file" id="input_dom_element"
              onChange={(e) => { handleFileAsync(e, "tabeller2", "A6") }}
              multiple />
            {/* //========================================= */}
          </Space>

        </div>
      </div>
      <div className="App">
        {/* <h1>{location.search?location.search:"Blog"}</h1> */}
        <h1>Šildymo daliklių ataskaita</h1>

        <div>
          <Table
            // loading={props.loading}
            id={"tabeller3"}
            dataSource={props.menesineData.menesineSD}
            columns={columnsSD}
            bordered={true}
            pagination={pagination}
            showHeader={true}
            size={'small'}
            title={() => { return "Namo šilumos daliklių rodmenys" }}

            Summary
            summary={(data1) => {

              // let total = 0;
              // data1.forEach(({ skirtumasred }) => {
              //   total += skirtumasred;
              // });
              let total = props.menesineData.menesineSD.reduce((total, { butasviso }) => {
                return total += butasviso;
              }, 0);


              return (
                <>

                  <Table.Summary.Row >
                    <Table.Summary.Cell index={0} colSpan={8} align={"right"}>Viso sąnaudos</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} colSpan={1} align={"left"}> <Text type="danger">{total}</Text> </Table.Summary.Cell>
                    {/* <Table.Summary.Cell index={2}> <Text>{totalRepayment}</Text> </Table.Summary.Cell> */}
                  </Table.Summary.Row>




                  {/* <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2}>
                          <Text type="danger">{totalBorrow - totalRepayment}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row> */}
                </>
              );
            }}
          />
          <Space wrap>
            <Button type="primary" icon={<DownloadOutlined />} onClick={
              () => {

                /* Create worksheet from HTML DOM TABLE */
                const table = document.getElementById('tabeller3').querySelector('table');

                // debugger;
                const wb = XLSX.utils.table_to_book(table);
                console.log(wb);
                /* Export to file (start a download) */
                XLSX.writeFile(wb, "SheetJSIntro.xlsx");

              }
            }>Atsiiųsti Exel failą</Button>
            {/* //=============================BANDYMAS */}

            <Button type="primary" icon={<DownloadOutlined />} onClick={
              () => {
                const fileName = props.menesineData.menesineKV[0].adresas_gatve + " g. " + props.menesineData.menesineKV[0].adresas_namo_nr + ".xlsx";
                const adresas = props.menesineData.menesineKV[0].adresas_gatve + " g. " + props.menesineData.menesineKV[0].adresas_namo_nr;
                const pradzios_data = props.menesineData.pradzios_data;
                const pabaigos_data = props.menesineData.pabaigos_data;
                const date = new Date(pabaigos_data);
                const praeitoPerPirmaDiena = (new Date(date.getFullYear(), date.getMonth() - 1, 1)).toLocaleDateString();
                const praeitoPerPaskDiena = (new Date(date.getFullYear(), date.getMonth(), 0)).toLocaleDateString();
                console.log(praeitoPerPirmaDiena + " " + praeitoPerPaskDiena);

                const wb = {
                  "Directory": {
                    "workbooks": [
                      "/xl/workbook.xml"
                    ],
                    "sheets": [
                      "/xl/worksheets/sheet1.xml"
                    ],
                    "charts": [],
                    "dialogs": [],
                    "macros": [],
                    "rels": [],
                    "strs": [
                      "/xl/sharedStrings.xml"
                    ],
                    "comments": [],
                    "threadedcomments": [],
                    "links": [],
                    "coreprops": [
                      "/docProps/core.xml"
                    ],
                    "extprops": [
                      "/docProps/app.xml"
                    ],
                    "custprops": [],
                    "themes": [
                      "/xl/theme/theme1.xml"
                    ],
                    "styles": [
                      "/xl/styles.xml"
                    ],
                    "vba": [],
                    "drawings": [],
                    "metadata": [],
                    "people": [],
                    "TODO": [],
                    "xmlns": "http://schemas.openxmlformats.org/package/2006/content-types",
                    "calcchain": "",
                    "sst": "/xl/sharedStrings.xml",
                    "style": "/xl/styles.xml",
                    "defaults": {
                      "rels": "application/vnd.openxmlformats-package.relationships+xml",
                      "xml": "application/xml"
                    }
                  },
                  "Workbook": {
                    "AppVersion": {
                      "appName": "xl",
                      "appname": "xl",
                      "lastEdited": "7",
                      "lastedited": "7",
                      "lowestEdited": "7",
                      "lowestedited": "7",
                      "rupBuild": "26924",
                      "rupbuild": "26924"
                    },
                    "WBProps": {
                      "defaultThemeVersion": 166925,
                      "allowRefreshQuery": false,
                      "autoCompressPictures": true,
                      "backupFile": false,
                      "checkCompatibility": false,
                      "CodeName": "",
                      "date1904": false,
                      "filterPrivacy": false,
                      "hidePivotFieldList": false,
                      "promptedSolutions": false,
                      "publishItems": false,
                      "refreshAllConnections": false,
                      "saveExternalLinkValues": true,
                      "showBorderUnselectedTables": true,
                      "showInkAnnotation": true,
                      "showObjects": "all",
                      "showPivotChartFilter": false,
                      "updateLinks": "userSet"
                    },
                    "WBView": [
                      {
                        "xWindow": "5280",
                        "xwindow": "5280",
                        "yWindow": "135",
                        "ywindow": "135",
                        "windowWidth": "22320",
                        "windowwidth": "22320",
                        "windowHeight": "17865",
                        "windowheight": "17865",
                        "uid": "{00000000-000D-0000-FFFF-FFFF00000000}",
                        "activeTab": 0,
                        "autoFilterDateGrouping": true,
                        "firstSheet": 0,
                        "minimized": false,
                        "showHorizontalScroll": true,
                        "showSheetTabs": true,
                        "showVerticalScroll": true,
                        "tabRatio": 600,
                        "visibility": "visible"
                      }
                    ],
                    "Sheets": [
                      {
                        "name": "Lapas1",
                        "sheetId": "1",
                        "sheetid": "1",
                        "id": "rId1",
                        "Hidden": 0
                      }
                    ],
                    "CalcPr": {
                      "calcId": "0",
                      "calcid": "0",
                      "calcCompleted": "true",
                      "calcMode": "auto",
                      "calcOnSave": "true",
                      "concurrentCalc": "true",
                      "fullCalcOnLoad": "false",
                      "fullPrecision": "true",
                      "iterate": "false",
                      "iterateCount": "100",
                      "iterateDelta": "0.001",
                      "refMode": "A1"
                    },
                    "Names": [],
                    "xmlns": "http://schemas.openxmlformats.org/spreadsheetml/2006/main",
                    "Views": [
                      {}
                    ]
                  },
                  "Props": {
                    "LastAuthor": "Rolandas Macius",
                    "Author": "Rolandas",
                    "CreatedDate": "2023-11-02T15:16:16.000Z",
                    "ModifiedDate": "2023-11-02T19:55:02.000Z",
                    "Application": "Microsoft Excel",
                    "AppVersion": "16.0300",
                    "DocSecurity": "0",
                    "HyperlinksChanged": false,
                    "SharedDoc": false,
                    "LinksUpToDate": false,
                    "ScaleCrop": false,
                    "Worksheets": 1,
                    "SheetNames": [
                      "Lapas1"
                    ]
                  },
                  "Custprops": {},
                  "Deps": {},
                  "Sheets": {
                    "Lapas1": {
                      "!ref": "A1:I4",
                      "A1": {
                        "t": "s",
                        "v": "RADIATORIŲ SUNAUDOTOS ŠILUMOS SUVESTINĖ",
                        "r": "<t>RADIATORIŲ SUNAUDOTOS ŠILUMOS SUVESTINĖ</t>",
                        "h": "RADIATORIŲ SUNAUDOTOS ŠILUMOS SUVESTINĖ",
                        "w": "RADIATORIŲ SUNAUDOTOS ŠILUMOS SUVESTINĖ"
                      },
                      "A2": {
                        "t": "s",
                        "v": adresas,
                        "r": "<t>Kviečių g. 22</t>",
                        "h": "Kviečių g. 22",
                        "w": "Kviečių g. 22"
                      },
                      "A3": {
                        "t": "s",
                        "v": "Einamas periodas",
                        "r": "<t>Einamas periodas</t>",
                        "h": "Einamas periodas",
                        "w": "Einamas periodas"
                      },
                      "D3": {
                        "t": "s",
                        "v": pradzios_data,
                        "w": "2/1/17"
                      },
                      "E3": {
                        "t": "s",
                        "v": pabaigos_data,
                        "w": "2/28/17"
                      },
                      "F3": {
                        "t": "s",
                        "v": "Ankstesnis periodas",
                        "r": "<t>Ankstesnis periodas</t>",
                        "h": "Ankstesnis periodas",
                        "w": "Ankstesnis periodas"
                      },
                      "H3": {
                        "t": "s",
                        "v": praeitoPerPirmaDiena,
                        "w": "1/1/17"
                      },
                      "I3": {
                        "t": "s",
                        "v": praeitoPerPaskDiena,
                        "w": "1/31/17"
                      },
                      "D4": {
                        "t": "s",
                        "v": "Reikšmės perskaičiuotos",
                        "r": "<t>Reikšmės perskaičiuotos</t>",
                        "h": "Reikšmės perskaičiuotos",
                        "w": "Reikšmės perskaičiuotos"
                      },
                      "!margins": {
                        "left": 0.7,
                        "right": 0.7,
                        "top": 0.75,
                        "bottom": 0.75,
                        "header": 0.3,
                        "footer": 0.3
                      },
                      "!merges": [
                        {
                          "s": {
                            "c": 0,
                            "r": 0
                          },
                          "e": {
                            "c": 8,
                            "r": 0
                          }
                        },
                        {
                          "s": {
                            "c": 0,
                            "r": 1
                          },
                          "e": {
                            "c": 3,
                            "r": 1
                          }
                        },
                        {
                          "s": {
                            "c": 0,
                            "r": 2
                          },
                          "e": {
                            "c": 2,
                            "r": 2
                          }
                        },
                        {
                          "s": {
                            "c": 5,
                            "r": 2
                          },
                          "e": {
                            "c": 6,
                            "r": 2
                          }
                        },
                        {
                          "s": {
                            "c": 3,
                            "r": 3
                          },
                          "e": {
                            "c": 6,
                            "r": 3
                          }
                        }
                      ]
                    }
                  },
                  "SheetNames": [
                    "Lapas1"
                  ],
                  "Strings": [
                    {
                      "t": "RADIATORIŲ SUNAUDOTOS ŠILUMOS SUVESTINĖ",
                      "r": "<t>RADIATORIŲ SUNAUDOTOS ŠILUMOS SUVESTINĖ</t>",
                      "h": "RADIATORIŲ SUNAUDOTOS ŠILUMOS SUVESTINĖ"
                    },
                    {
                      "t": "Kviečių g. 22",
                      "r": "<t>Kviečių g. 22</t>",
                      "h": "Kviečių g. 22"
                    },
                    {
                      "t": "Einamas periodas",
                      "r": "<t>Einamas periodas</t>",
                      "h": "Einamas periodas"
                    },
                    {
                      "t": "Ankstesnis periodas",
                      "r": "<t>Ankstesnis periodas</t>",
                      "h": "Ankstesnis periodas"
                    },
                    {
                      "t": "Reikšmės perskaičiuotos",
                      "r": "<t>Reikšmės perskaičiuotos</t>",
                      "h": "Reikšmės perskaičiuotos"
                    },
                    {
                      "t": ""
                    }
                  ],
                  "Styles": {
                    "Fonts": [
                      {
                        "sz": 11,
                        "color": {
                          "theme": 1
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "theme": 1
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 18,
                        "color": {
                          "theme": 3
                        },
                        "name": "Calibri Light",
                        "family": 2,
                        "scheme": "major"
                      },
                      {
                        "bold": 1,
                        "sz": 15,
                        "color": {
                          "theme": 3
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "bold": 1,
                        "sz": 13,
                        "color": {
                          "theme": 3
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "bold": 1,
                        "sz": 11,
                        "color": {
                          "theme": 3
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "rgb": "006100"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "rgb": "9C0006"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "rgb": "9C5700"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "rgb": "3F3F76"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "bold": 1,
                        "sz": 11,
                        "color": {
                          "rgb": "3F3F3F"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "bold": 1,
                        "sz": 11,
                        "color": {
                          "rgb": "FA7D00"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "rgb": "FA7D00"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "bold": 1,
                        "sz": 11,
                        "color": {
                          "theme": 0
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "rgb": "FF0000"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "italic": 1,
                        "sz": 11,
                        "color": {
                          "rgb": "7F7F7F"
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "bold": 1,
                        "sz": 11,
                        "color": {
                          "theme": 1
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      },
                      {
                        "sz": 11,
                        "color": {
                          "theme": 0
                        },
                        "name": "Calibri",
                        "family": 2,
                        "scheme": "minor"
                      }
                    ],
                    "Fills": [
                      {
                        "patternType": "none"
                      },
                      {
                        "patternType": "gray125"
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "rgb": "C6EFCE"
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "rgb": "FFC7CE"
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "rgb": "FFEB9C"
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "rgb": "FFCC99"
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "rgb": "F2F2F2"
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "rgb": "A5A5A5"
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "rgb": "FFFFCC"
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 4
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 4,
                          "tint": 0.7999816888943144
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 4,
                          "tint": 0.5999938962981048
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 4,
                          "tint": 0.3999755851924192
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 5
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 5,
                          "tint": 0.7999816888943144
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 5,
                          "tint": 0.5999938962981048
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 5,
                          "tint": 0.3999755851924192
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 6
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 6,
                          "tint": 0.7999816888943144
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 6,
                          "tint": 0.5999938962981048
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 6,
                          "tint": 0.3999755851924192
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 7
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 7,
                          "tint": 0.7999816888943144
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 7,
                          "tint": 0.5999938962981048
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 7,
                          "tint": 0.3999755851924192
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 8
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 8,
                          "tint": 0.7999816888943144
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 8,
                          "tint": 0.5999938962981048
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 8,
                          "tint": 0.3999755851924192
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 9
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 9,
                          "tint": 0.7999816888943144
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 9,
                          "tint": 0.5999938962981048
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      },
                      {
                        "patternType": "solid",
                        "fgColor": {
                          "theme": 9,
                          "tint": 0.3999755851924192
                        },
                        "bgColor": {
                          "indexed": 65
                        }
                      }
                    ],
                    "Borders": [
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {}
                    ],
                    "CellXf": [
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 0,
                        "borderid": "0",
                        "xfId": 0,
                        "xfid": "0"
                      },
                      {
                        "numFmtId": 14,
                        "numfmtid": "14",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 0,
                        "borderid": "0",
                        "xfId": 0,
                        "xfid": "0",
                        "applyNumberFormat": true,
                        "applynumberformat": "1"
                      },
                      {
                        "numFmtId": 0,
                        "numfmtid": "0",
                        "fontId": 0,
                        "fontid": "0",
                        "fillId": 0,
                        "fillid": "0",
                        "borderId": 0,
                        "borderid": "0",
                        "xfId": 0,
                        "xfid": "0"
                      }
                    ]
                  },
                  "Themes": {},
                  "SSF": {
                    "0": "General",
                    "1": "0",
                    "2": "0.00",
                    "3": "#,##0",
                    "4": "#,##0.00",
                    "9": "0%",
                    "10": "0.00%",
                    "11": "0.00E+00",
                    "12": "# ?/?",
                    "13": "# ??/??",
                    "14": "m/d/yy",
                    "15": "d-mmm-yy",
                    "16": "d-mmm",
                    "17": "mmm-yy",
                    "18": "h:mm AM/PM",
                    "19": "h:mm:ss AM/PM",
                    "20": "h:mm",
                    "21": "h:mm:ss",
                    "22": "m/d/yy h:mm",
                    "37": "#,##0 ;(#,##0)",
                    "38": "#,##0 ;[Red](#,##0)",
                    "39": "#,##0.00;(#,##0.00)",
                    "40": "#,##0.00;[Red](#,##0.00)",
                    "45": "mm:ss",
                    "46": "[h]:mm:ss",
                    "47": "mmss.0",
                    "48": "##0.0E+0",
                    "49": "@",
                    "56": "\"上午/下午 \"hh\"時\"mm\"分\"ss\"秒 \""
                  },
                  "bookType": "xlsx"
                };
                // console.log(wb);
                const table = document.getElementById('tabeller3').querySelector('table');
                const ws = wb.Sheets["Lapas1"];
                XLSX.utils.sheet_add_dom(ws, table, { origin: "A5" });
                // alert("Veikia");
                XLSX.writeFile(wb, fileName);
              }
            }>Atsiiųsti Exel failą su šablonu</Button>

            <label for="input_dom_element">Pasirinkite failo šabloną</label>
            <input type="file" id="input_dom_element"
              onChange={(e) => { handleFileAsync(e, "tabeller3", "A5") }}
              multiple />
            {/* //========================================= */}
          </Space>
        </div>
      </div>

    </>
  )

}



const Menesine1 = (props) => {
  // debugger;
  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.state) {
      setSearchParams(location.state);
      // alert("Veikia");  
    } else if (location.search) {
      // console.log(location.search);
      // props.getMenesineThunkCreator(location.search, setLoading)
      props.getMenesineRawThunkCreator(location.search, setLoading)

    }
  }, [location])
  const onSubmit = () => {
    props.getExcelThunkCreator(props.menesineData);
  }

  return (
    <>
      {/* <Menesine2 menesineData={props.menesineData} loading={loading} onSubmit={onSubmit}/> */}
      <Menesine2 menesineData={props.menesineData} loading={loading} onSubmit={onSubmit} />
    </>
  )
}

// *Lenetelės parametrus galima nurodyti ir funkcijos viduje ir už jos ribų*****************************************************
//   const pagination = {
//     position: ['bottomLeft'],
//     defaultPageSize: '10',
//     showSizeChanger: true,
//     pageSizeOptions: [10, 20, 25, 50, 100, 150, 200]
// };
//  debugger;
// const columns = [
//     {
//         title: 'Buto numeris',
//         dataIndex: 'buto_Nr',
//         key: 'buto_Nr',
//         filterSearch: true,
//         filters:             //Formuojame filtravimo menu

//         data1.map(item => { return { text: item.buto_Nr, value: item.buto_Nr}}),
//         filterMode: 'tree',
//         //     // onFilter: ((value, item) => item.uniqueUrlName.includes(value)), // Filtravimo funkcija
//         //     // //   filterDropdownOpen: false,
//         //     // //   filteredValue:uniqueUrlName[],
//         //     // // filterMultiple:true, 

//         // sorter: (a, b) => {
//         //     const nameA = a.name.toUpperCase(); // ignore upper and lowercase
//         //     const nameB = b.name.toUpperCase(); // ignore upper and lowercase
//         //     if (nameA < nameB) {
//         //         return -1;
//         //     }
//         //     if (nameA > nameB) {
//         //         return 1;
//         //     }

//         //     // names must be equal
//         //     return 0;

//         // }, // Vardų rikiavimas

//     },

//     // {
//     //     title: 'kamb_padetis',
//     //     dataIndex: 'kamb_padetis',
//     //     key: 'kamb_padetis',
//     //     // sorter: (a, b) => {
//     //     //     const nameA = a.uniqueUrlName.toUpperCase(); // ignore upper and lowercase
//     //     //     const nameB = b.uniqueUrlName.toUpperCase(); // ignore upper and lowercase
//     //     //     if (nameA < nameB) {
//     //     //         return -1;
//     //     //     }
//     //     //     if (nameA > nameB) {
//     //     //         return 1;
//     //     //     }

//     //     //     // names must be equal
//     //     //     return 0;
//     //     // },
//     //     // filterSearch: true,
//     //     // filters:             //Formuojame filtravimo menu
//     //     //     masyvas1.map(item => { return { text: item, value: item } })
//     //     // //  [ {
//     //     // //         text: "Gaidys",
//     //     // //         value: "Gaidys"
//     //     // //     },
//     //     // //     {
//     //     // //         text: "Kiaulė",
//     //     // //         value: "Kiaulė"
//     //     // //     }]
//     //     // ,
//     //     // filterMode: 'tree',
//     //     // onFilter: ((value, item) => item.uniqueUrlName.includes(value)), // Filtravimo funkcija
//     //     // //   filterDropdownOpen: false,
//     //     // //   filteredValue:uniqueUrlName[],
//     //     // // filterMultiple:true,


//     // },
//     {
//         title: 'Kambarys',
//         dataIndex: 'kambarys',
//         key: 'kambarys',
//     },
//     {
//       title: 'Skaitiklio Nr',
//       dataIndex: 'skaitiklio_Nr',
//       key: 'skaitiklio_Nr',
//     },
//     // {
//     //     title: 'kt',
//     //     dataIndex: 'kt',
//     //     key: 'kt',
//     // },    
//   {
//     title: 'Periodo pradžia',
//     dataIndex: 'pradzia',
//     key: 'pradzia',
// }, 
// {
//   title: 'Laikas',
//   dataIndex: 'pradzia_laikas',
//   key: 'pradzia_laikas',
// },
// {
//   title: 'Periodo pabaiga',
//   dataIndex: 'pabaiga',
//   key: 'pabaiga',
// },
// {
// title: 'Laikas',
// dataIndex: 'pabaiga_laikas',
// key: 'pabaiga_laikas',
// },  
// // {
// //   title: 'rad_galia',
// //   dataIndex: 'rad_galia',
// //   key: 'rad_galia',
// //   // render: (followed) => { return <Checkbox1 data={followed} /> }
// // }, 
// // {
// //   title: 'rad_medziaga',
// //   dataIndex: 'rad_medziaga',
// //   key: 'rad_medziaga',
// //   // render: (followed) => { return <Checkbox1 data={followed} /> }
// // }, 
// {
//   title: 'Daviklio rodmenys',
//   dataIndex: 'rodmenys1',
//   key: 'rodmenys1',  
// }, 
// {
//   title: 'Redaguoti rodmenys',
//   dataIndex: 'rodmenys1_red',
//   key: 'rodmenys1_red',
// }, 
// {
//   title: 'Daviklio rodmenys',
//   dataIndex: 'rodmenys2',
//   key: 'rodmenys2',
// }, 
// {
//   title: 'Redaguoti rodmenys',
//   dataIndex: 'rodmenys2_red',
//   key: 'rodmenys2_red',
// }, 
// {
//   title: 'Periodo sąnaudos',
//   dataIndex: 'sanaudos',
//   key: 'sanaudos',
// }, 
// {
//   title: 'Sąnaudos redaguotos',
//   dataIndex: 'sanaudos_redaguotos',
//   key: 'sanaudos_redaguotos',
// }, 

// {
//   title: 'Buto sąnaudos',
//   dataIndex: 'buto_sanaudos',
//   key: 'buto_sanaudos',
// },
// ];

// ***********************************************************














let mapStateToProps = (state) => {
  // debugger;
  return {
    menesineData: state.data,

    // id: state.data.id
  }
}
// debugger;
let mapDispatchToProps = (dispatch) => {
  // debugger;
  return {
    getExcelThunkCreator: (data) => { dispatch(getExcelThunkCreator(data)) },
    getMenesineThunkCreator: (data, setLoading) => { dispatch(getMenesineThunkCreator(data, setLoading)) },
    getMenesineRawThunkCreator: (data, setLoading) => { dispatch(getMenesineRawThunkCreator(data, setLoading)) },
  }
}
// export const Menesine = connect(mapStateToProps, mapDispatchToProps)(Menesine1);




export const Menesine = compose(
  connect(mapStateToProps, mapDispatchToProps),
  WithNavigateAuth
)(Menesine1);




//******************** */

// export default Blog;