import { Link } from "react-router-dom";
// import { Atsijungimas } from "./Header/Header1";
// import { deleteUserThunkCreator } from "../redux/auth_Reducer.ts";
import { connect } from 'react-redux';
import { Card, Space } from "antd";
import Paieska from "./Formos/Paieska";
import { FileSend } from "./File/File_send";
import { File } from "./File/File";
import { PaieskaA } from "./Paieska/Paieska";
// import FileUpload from "../FileUpload";
import style from "./Ataskaitos.module.css";
import { WithNavigateAuth } from "../../Hoc/WithAuthNavigate";
import { compose } from "redux";

const Ataskaitos1 = (props) => {
    return (
        <div > <h1>Ataskaitos</h1>
            <Space
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }} 
            >
                <Card className={style.container} title="Mėnesinė ataskaita" size="small">
                    <Link to='/' >Grįžti į pagrindinį puslapį</Link>
                    {/* <p>Pasirinkite data kuriai norite gauti ataskaitą: </p> */}
                    <Paieska/>
                    {/* <p>{props.auth.email?props.auth.email:"Svečias"}</p>
                    {props.auth.email ? <Atsijungimas deleteUserThunkCreator={props.deleteUserThunkCreator} email={props.email} /> : <Link to='/login' >Prisijungti</Link>} */}
                </Card>
                <Card className={style.container} title="Kitos ataskaitos" size="small">
                {/* <Link to='/signin' >Sukurti naują vartotoją</Link> */}
                    <PaieskaA />
                </Card>
                <Card className={style.container} title="Card" size="small">
                    <FileSend/>
                    {/* <File/> */}
                    {/* <FileUpload/> */}
                </Card>
            </Space>
        </div>
    )
};

// ***********Formos*********************???????????




//==========================================
// Galimas toks variantas Hoc kai redirektina į prisijungima kai nėra prisijungta, arba galimas per compose, apačioje.
// let Ataskaitos2 = withNavigateAuth (Ataskaitos1);
//===============================================







// ********Kuriame konteinerį duomenų perdavimui į komponentą Hoc withNavigateAuth**********************

const mapStateToProps = (state) => {
    return {
        // auth: state.auth,
    }
};
let mapDispatchToProps = (dispatch) => {
    return {
        // deleteUserThunkCreator: () => { dispatch(deleteUserThunkCreator()); },
    }
}

export const Ataskaitos = compose(
connect(mapStateToProps, mapDispatchToProps),
WithNavigateAuth
)(Ataskaitos1);

//*************************************************************************************************** */

