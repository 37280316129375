// import * as axios from 'axios';
import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  // baseURL: "https://backend",
  baseURL: "https://backend.macrol.lt",
  headers: {
    // 'Content-Type': 'application/json', // type
    // 'Access-Control-Allow-Credentials': true,
    // 'Access-Control-Allow-Headers': 'Coookie'
  },
});

export const dataApi = {
  getdata() {
    // debugger;
    // return fetch("https://backend/ataskaita", {
    return fetch("https://backend.macrol.lt/ataskaita", {
      method: "GET",
      headers: {
        "Content-Type": "application/json", // type
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Coookie",
      },
      mode: "cors",
      credentials: "include",
      // body: JSON.stringify(data),
    })
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  menesine(params) {
    // return (fetch("https://backend/ataskaita/menesine" + params, {
    return (
      fetch("https://backend.macrol.lt/ataskaita/menesine" + params, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // type
          // 'Authorization': jwt
        },
        mode: "cors",
        // credentials: 'same-origin',
        credentials: "include",
      })
        .then((response) => {
          // console.log(response.json());
          // return response;
          // console.log(response);
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          //   console.log("Skaitikliai veikia");
          return data;
          // return "data.rezultatas";
        })

        // .then(data => document.getElementById("outputDiv").innerHTML = data.message)
        .catch((error) => {
          console.error(error);
        })
    );

    // modal2.style.display = "none";
  },
  bandymas(params) {
    // return (fetch("https://backend/ataskaita/bandymas" + params, {
    return (
      fetch("https://backend.macrol.lt/ataskaita/bandymas" + params, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // type
          // 'Authorization': jwt
        },
        mode: "cors",
        // credentials: 'same-origin',
        credentials: "include",
      })
        .then((response) => {
          // console.log(response.json());
          // return response;
          // console.log(response);
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          //   console.log("Skaitikliai veikia");
          return data;
          // return "data.rezultatas";
        })

        // .then(data => document.getElementById("outputDiv").innerHTML = data.message)
        .catch((error) => {
          console.error(error);
        })
    );

    // modal2.style.display = "none";
  },
  getExcel(data) {
    // return (fetch("https://backend/exel", {
    return (
      fetch("https://backend.macrol.lt/exel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // type
          // 'Authorization': jwt
        },
        mode: "cors",
        // credentials: 'same-origin',
        credentials: "include",
        body: JSON.stringify(data),
      })
        .then((response) => {
          // return response.json();
          return response.blob();
        })
        .then((data) => {
          // console.log(data);
          // console.log("Skaitikliai veikia");
          //
          // (C1) FILE DATA IS "READY FOR USE"
          console.log(data);

          // (C2) TO "FORCE DOWNLOAD"
          var url = window.URL.createObjectURL(data),
            anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "saveas.xls";
          anchor.click();
          console.log(anchor);
          // (C3) CLEAN UP
          window.URL.revokeObjectURL(url);
          // document.removeChild(anchor);
        })

        // .then(data => document.getElementById("outputDiv").innerHTML = data.message)
        .catch((error) => {
          console.error(error);
        })
    );

    // modal2.style.display = "none";
  },
  // Parsiunčia ataskaitą
  // getProfile (userid) {
  //     // debugger;
  //     return instance.get(`profile/${userid}`)
  //     .then(response => response.data)
  // }
  // axios.delete(`https://social-network.samuraijs.com/api/1.0/follow/${curentvalue.id}`, {
  //                                     withCredentials: true,
  //                                     headers: {
  //                                         "API-KEY" : "1e0134e8-cb3b-43c6-b5a0-82d0110bb9a7"
  //                                     }
  //                                 })
  // axios.post(`https://social-network.samuraijs.com/api/1.0/follow/${curentvalue.id}`, {}, {
  //     withCredentials: true,
  //     headers: {
  //         "API-KEY" : "1e0134e8-cb3b-43c6-b5a0-82d0110bb9a7"
  //     }
  // })
};

export const userApi = {
  login(data) {
    // return fetch("https://backend/login", {
    return fetch("https://backend.macrol.lt/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // type
        "Access-Control-Allow-Credentials": true,
        // 'Access-Control-Allow-Headers': 'Coookie',
      },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((response) => {
        // *** Iš antraštės paima reikšmes
        // debugger;
        // response.headers.forEach((val, key) => {
        //     console.log(key, val);
        //   });
        //   console.log(response.headers);
        //   let cookie = response.headers.get('magic');
        //   console.log('set-cookie header value', cookie);
        //   let kukis = document.cookie;
        //   console.log(kukis);

        return response.json();
      })
      .then((data) => {
        // debugger;
        // let a = document.cookie;
        // console.log(a);
        // document.cookie = "expires="+data.expires;
        document.cookie = `AuthorizationCookie = ${data.token}`;
        sessionStorage.setItem("authToken", data.token);
        // modal2.style.display = "none";
        // location.hash = "";
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  readuser() {
    // return fetch("https://backend/readuser", {
    return fetch("https://backend.macrol.lt/readuser", {
      method: "GET",
      headers: {
        "Content-Type": "application/json", // type
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Coookie",
      },
      mode: "cors",
      credentials: "include",
      // body: JSON.stringify(data),
    })
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((data) => {
        // debugger;
        // document.cookie = "email="+data.email;
        // modal2.style.display = "none";
        // location.hash = "";
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  deleteuser() {
    // return fetch("https://backend/deleteuser", {
    return fetch("https://backend.macrol.lt/deleteuser", {
      method: "GET",
      headers: {
        "Content-Type": "application/json", // type
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Coookie",
      },
      mode: "cors",
      credentials: "include",
      // body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        sessionStorage.clear("authToken");
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  signinuser(data) {
    // debugger;
    // return fetch("https://backend/createuser", {
    return fetch("https://backend.macrol.lt/createuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // type
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Coookie",
      },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  login1({ email, password }) {
    // debugger;
    return instance
      .post(`login`, { email, password })
      .then((response) => response.data);
    // .then(data => return data)
  },
};
