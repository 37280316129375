import logo from "./logo.svg";
import "./App.css";
import { Link, Routes, Route, Navigate } from "react-router-dom";
import { Homepage } from "./Components/Homepage";
import { About } from "./Components/About";
import { Blog } from "./Components/Blog";
import { Layout1 } from "./Components/Layout";
import { Table1 } from "./Components/Table";
import { Users } from "./Components/Users";
import Login from "./Components/Login/Login";
import { useEffect } from "react";
import { connect } from "react-redux";
import { readUserThunkCreator } from "./redux/auth_Reducer.ts";
import Signin from "./Components/Login/Signin";
import { Ataskaitos } from "./Components/Ataskaitos/Ataskaitos";
import { Menesine } from "./Components/Ataskaitos/Menesine/Menesine";
import AuthVerify from "./Components/common/auth-verify";
import Admin from "./Components/Admin";
import { Email } from "./Components/Email";
import { UnautorizedPage } from "./Components/UnautorizedPage";
import { RequireAuth, WithNavigateAuthKitas } from "./Hoc/WithAuthNavigate.js";
import { Loading } from "./Hoc/Loading";

// debugger;
function App1(props) {
  // Automatinis prisiloginimas kiekviename App renderinge. Tai turi vykti su cookies.
  const loginData = {
    password: "senamyja",
    email: "rolandas.macius@gmail.com",
  };

  useEffect(() => {
    // debugger;
    props.readUserThunkCreator();
  }, [props.isAuth]);

  return (
    <div>
      <Routes>
        {/* Component Layout sukuria šabloną, kur visus dukterinius routus talpina į šablone Layout  Outlet nurodytą vietą */}
        <Route path="" element={<Layout1 />}>
          <Route index element={<Homepage />} />
          <Route element={<Loading />}>
            <Route path="/users" element={<Users />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/signin" element={<Signin />} />

          {/* Privatūs routai */}
          <Route caseSensitive path="/ataskaitos" element={<Ataskaitos />} />
          <Route path="/menesine/*" element={<Menesine />} />

          <Route
            caseSensitive
            path="/about"
            element={
              <WithNavigateAuthKitas>
                <About />
              </WithNavigateAuthKitas>
            }
          />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/verify" element={<Navigate to="/about" replace />} />

          <Route path="/unautorizedpage" element={<UnautorizedPage />} />
          <Route element={<RequireAuth allowedRoles={[111, 222, 333]} />}>
            <Route path="/admin" element={<Admin />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[111, 222, 333]} />}>
            <Route path="/blog" element={<Blog />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[111, 222, 333]} />}>
            <Route path="/table" element={<Table1 />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[111, 222, 333]} />}>
            <Route path="/email" element={<Email />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

// export default App;

// debugger;

let mapStateToProps = (state) => {
  // debugger;
  return {
    isAuth: state.auth.isAuth,
    // captcha: state.auth.captcha
  };
};
// debugger;
let mapDispatchToProps = (dispatch) => {
  // debugger;
  return {
    readUserThunkCreator: () => {
      dispatch(readUserThunkCreator());
    },

    // set_user_data: (id, login, email) => { dispatch(set_user_dataActionCreator(id, login, email)); },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(App1);
export default App;
