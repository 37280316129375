import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { readUserThunkCreator} from "../redux/auth_Reducer.ts";


// export const withNavigateAuth = (Component) => {

//     if (props.isAuth==false) {
//         return <Navigate to={'/login'}/>
//     }
//     return (
//         <Component {...props} />
//     )
// } 




// Klasinis Hocas--------------
// export const withNavigateAuth = (Comp) => {
//     class NavigateComponentClass extends React.Component  {
//         render() {
//             if (this.props.isAuth==false) {
//                 // alert("Jūs neprisiloginęs");
//                 return <Navigate to={'/login'}/>
//             }
//             return <Comp {...this.props}/>

//         }
//     }
//     // debugger;

//     const NavigateComponent = connect(mapStateToPropsNavigate)(NavigateComponentClass);

//     return NavigateComponent;
// }; 

// Funkcinis Hoc'as
// Senasis variantas *******************************


export const WithNavigateAuth = (Comp) => {

    let mapStateToPropsNavigate = (state) => {
        return {
            isAuth: state.auth.isAuth,
        }
    }
    let mapDispatchToProps = (dispatch) => {
        // debugger;
        return {
            readUserThunkCreator:() => {dispatch(readUserThunkCreator());},

            // set_user_data: (id, login, email) => { dispatch(set_user_dataActionCreator(id, login, email)); },
        }
    }
    const NavigateComponentF = (props) => {
        const location = useLocation();
        props.readUserThunkCreator();
        if (props.isAuth == false) {
            // alert("Jūs neprisiloginęs");
            return <Navigate to={'/login'} state={{from:location}}/>
        }
        return <Comp {...props} />
    }
    const NavigateComponent = connect(mapStateToPropsNavigate, mapDispatchToProps)(NavigateComponentF);
    return NavigateComponent;
}; 

// *********************************************************************************

// *****Naujas variantas*********************Šitas HOC veikia per Route element

const WithNavigateAuthKitas1 = ({ children, ...props }) => {
    const location = useLocation();
    if (props.isAuth == false) {        
        // alert("Jūs neprisiloginęs");
        // console.log(location);
        return <Navigate to={'/login'} state={{ from: location }} />
    }
    return children
       
    

}

    let mapStateToPropsNavigate = (state) => {
        return {
            isAuth: state.auth.isAuth,
            role: state.auth.role,
        }
    }
    let mapDispatchToProps = (dispatch) => {
        // debugger;
        return {
            readUserThunkCreator:() => {dispatch(readUserThunkCreator());},
            // set_user_data: (id, login, email) => { dispatch(set_user_dataActionCreator(id, login, email)); },
        }
    }
   
    export const WithNavigateAuthKitas = connect(mapStateToPropsNavigate, mapDispatchToProps)(WithNavigateAuthKitas1);


    const auth = {roles:[1, 4, 25, 30], user:"Rolandas"};

    

    export const RequireAuth1 = ({ allowedRoles, ...props }) => {
        // const { auth } = useAuth();
        // const location = useLocation();
      
        

        const location = useLocation();
        const fromPage = location.state?.from?.pathname || '/';
        // console.log("Gaidys ");
        // console.log(auth?.roles?.find(role => allowedRoles?.includes(role)));
        const roles = [parseInt(props.role)];
        
        return (
            roles?.find(role => allowedRoles?.includes(role))
                ? <Outlet />
                : props.isAuth
                    ? <Navigate to="/unautorizedpage" state={{ from: location }} replace />
                    : <Navigate to="/login" state={{ from: location }} replace />
        );
    }
    
      
    export const RequireAuth = connect(mapStateToPropsNavigate, mapDispatchToProps)(RequireAuth1);



