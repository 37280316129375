import { Image } from 'antd';




export const Image1 = ({url}, {...props}) => (
  <Image
    width={200}
    src={url}
  />
);
