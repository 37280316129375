import React, { useEffect } from "react";
import c from './Users.module.css';
import preloader from "./Spinner-1s-200px1.svg";



const Preloader = (props) => {
    return <div>
         <img src ={preloader} className={c.spiner}/>
    </div> 
}

export default Preloader;