import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
// import { reduxForm } from "redux-form";

const { Dragger } = Upload;

const props = {
  // openFileDialogOnClick: false,
  withCredentials: true,
  name: "Išsiunčiamas failas",
  multiple: false,
  // action: "https://backend/failas",
  action: "https://backend.macrol.lt/failas",
  // data: {
  //   "fileInfo": {
  //       "01:file": "202308220638.bil",
  //       "02:device ID": "10323161",
  //       "03:fabrication no.": "10323161",
  //       "04:software version": "32",
  //       "05:date": "22.08.2023",
  //       "06:day of week": "2",
  //       "07:time": "06:38:54",
  //       "08:operating hours": "15714",
  //       "09:error code": "0",
  //       "10:error date": "x x x",
  //       "11:error time": "x x x",
  //       "13:number of dev.": "112",
  //       "14:type": "WTT16",
  //       "\r": "\r"
  //   },
  //   "ataskaitosDuomenys": {
  //       "51692604": {
  //           "01:number": "56",
  //           "03:NW node": "2",
  //           "05:readout date": "21.08.2023",
  //           "06:readout time": "23:56:50",
  //           "09:address": "2177.2.253",
  //           "10:device ID": "51692604",
  //           "11:fabr. no.": "51692604",
  //           "12:manufacturer": "12901",
  //           "13:sw. version": "26",
  //           "14:medium": "6",
  //           "15:device date": "21.08.2023",
  //           "16:device time": "22:48:00",
  //           "17:operating hours": "x x x",
  //           "18:error code": "0",
  //           "19:error date": "x x x",
  //           "20:error time": "x x x",
  //           "21:error duration": "x x x",
  //           "22:cum. energy": "x x x",
  //           "24:dim. cum. energy": "x x x",
  //           "26:cum. volume": "0,41",
  //           "27:dim. cum. volume": "m3",
  //           "30:energy on set day": "x x x",
  //           "32:dim. energy on set day": "x x x",
  //           "34:volume on set day": "0",
  //           "35:dim. volume on set day": "m3",
  //           "36:set day": "x x x",
  //           "37:energy on  2nd set day": "x x x",
  //           "38:dim. energy on 2nd set day": "x x x",
  //           "39:volume on 2nd set day": "x x x",
  //           "40:dim. vol. on 2nd set day": "x x x",
  //           "41:2nd set day": "x x x",
  //           "42:max. value": "x x x",
  //           "43:dim. max. value": "x x x",
  //           "44:date of max. value": "x x x",
  //           "45:tariff1 value": "x x x",
  //           "46:dim. tariff1 value": "x x x",
  //           "47:tariff2 value": "x x x",
  //           "48:dim. tariff2 value": "x x x",
  //           "49:tariff1 value on set day": "x x x",
  //           "50:dim. tariff1 on set day": "x x x",
  //           "51:tariff2 value on set day": "x x x",
  //           "52:dim. tariff2 on set day": "x x x",
  //           "53:stat. date": "31.07.2023",
  //           "54:stat. value01": "0,033",
  //           "55:stat. value02": "x x x",
  //           "56:stat. value03": "x x x",
  //           "57:stat. value04": "x x x",
  //           "58:stat. value05": "x x x",
  //           "59:stat. value06": "x x x",
  //           "60:stat. value07": "x x x",
  //           "61:stat. value08": "x x x",
  //           "62:stat. value09": "x x x",
  //           "63:stat. value10": "x x x",
  //           "64:stat. value11": "x x x",
  //           "65:stat. value12": "x x x",
  //           "66:stat. value13": "x x x",
  //           "67:stat. value14": "x x x",
  //           "68:stat. value15": "x x x",
  //           "69:stat. value16": "x x x",
  //           "70:stat. value17": "x x x",
  //           "71:stat. value18": "x x x",
  //           "72:dimension of stat.": "m3",
  //           "\r": "\r"
  //       },
  //       "51692605": {
  //           "01:number": "57",
  //           "03:NW node": "2",
  //           "05:readout date": "21.08.2023",
  //           "06:readout time": "22:08:00",
  //           "09:address": "2177.2.253",
  //           "10:device ID": "51692605",
  //           "11:fabr. no.": "51692605",
  //           "12:manufacturer": "12901",
  //           "13:sw. version": "26",
  //           "14:medium": "6",
  //           "15:device date": "21.08.2023",
  //           "16:device time": "21:00:00",
  //           "17:operating hours": "x x x",
  //           "18:error code": "0",
  //           "19:error date": "x x x",
  //           "20:error time": "x x x",
  //           "21:error duration": "x x x",
  //           "22:cum. energy": "x x x",
  //           "24:dim. cum. energy": "x x x",
  //           "26:cum. volume": "0,69",
  //           "27:dim. cum. volume": "m3",
  //           "30:energy on set day": "x x x",
  //           "32:dim. energy on set day": "x x x",
  //           "34:volume on set day": "0",
  //           "35:dim. volume on set day": "m3",
  //           "36:set day": "x x x",
  //           "37:energy on  2nd set day": "x x x",
  //           "38:dim. energy on 2nd set day": "x x x",
  //           "39:volume on 2nd set day": "x x x",
  //           "40:dim. vol. on 2nd set day": "x x x",
  //           "41:2nd set day": "x x x",
  //           "42:max. value": "x x x",
  //           "43:dim. max. value": "x x x",
  //           "44:date of max. value": "x x x",
  //           "45:tariff1 value": "x x x",
  //           "46:dim. tariff1 value": "x x x",
  //           "47:tariff2 value": "x x x",
  //           "48:dim. tariff2 value": "x x x",
  //           "49:tariff1 value on set day": "x x x",
  //           "50:dim. tariff1 on set day": "x x x",
  //           "51:tariff2 value on set day": "x x x",
  //           "52:dim. tariff2 on set day": "x x x",
  //           "53:stat. date": "31.07.2023",
  //           "54:stat. value01": "0,032",
  //           "55:stat. value02": "x x x",
  //           "56:stat. value03": "x x x",
  //           "57:stat. value04": "x x x",
  //           "58:stat. value05": "x x x",
  //           "59:stat. value06": "x x x",
  //           "60:stat. value07": "x x x",
  //           "61:stat. value08": "x x x",
  //           "62:stat. value09": "x x x",
  //           "63:stat. value10": "x x x",
  //           "64:stat. value11": "x x x",
  //           "65:stat. value12": "x x x",
  //           "66:stat. value13": "x x x",
  //           "67:stat. value14": "x x x",
  //           "68:stat. value15": "x x x",
  //           "69:stat. value16": "x x x",
  //           "70:stat. value17": "x x x",
  //           "71:stat. value18": "x x x",
  //           "72:dimension of stat.": "m3",
  //           "\r": "\r"
  //       }
  // },

  // onChange(info) {
  //   // e.preventDefault();
  //   // alert("onChange veikia");
  //   const { status } = info.file;
  //   if (status !== 'uploading') {
  //     console.log(info.file, info.fileList);
  //   }
  //   if (status === 'done') {
  //     message.success(`${info.file.name} file uploaded successfully.`);
  //   } else if (status === 'error') {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  //   console.log(info);
  //   console.log(Upload);
  //   // console.log(info.file, info.fileList);
  // },

  // //on|Drop suveikia kai failą perkeliame kaip windows su pelyte iš katalogo ant naršyklės lango
  // onDrop(e) {
  //   // debugger;
  //   alert("onDrop veikia");
  //   console.log('Dropped files', e.dataTransfer.files);
  //   e.preventDefault();
  // },
  // },
  onChange(info) {
    // e.preventDefault();
    // alert("onChange veikia");
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    console.log(status);
    // console.log(info);
    // console.log(Upload);
    // console.log(info.file, info.fileList);
  },

  //on|Drop suveikia kai failą perkeliame kaip windows su pelyte iš katalogo ant naršyklės lango
  onDrop(e) {
    // debugger;
    alert("onDrop veikia");
    console.log("Dropped files", e.dataTransfer.files);
    e.preventDefault();
  },
  //================================================
  // data: async (e) => {
  //   // file.preventDefault();
  //   // alert("data veikia");
  //   console.log('Pirmas');
  //   const file = e;

  //   const reader = new FileReader();

  //   // debugger;
  //   // ------------------------------------
  //   let resultArrGalutinis = new Object;
  //   resultArrGalutinis = { "Rezultatas_pirminis": "Dar nepakeistas" };

  //   reader.addEventListener('load', (event) => load().then((resultArrGalutinis) => { console.log('Antras po then'); paskutinis(); }));
  //   async function load() {

  //     let arr = reader.result;
  //     let resultArrAntras = new Array;

  //     resultArrAntras = Array.from(arr.split("\n")).map(item => { return item.split("\t") });

  //     // console.log(resultArrAntras);

  //     let resultArrTrecias = new Object;
  //     resultArrGalutinis.fileInfo = {};
  //     resultArrGalutinis.ataskaitosDuomenys = {};
  //     let keys = resultArrAntras[0];
  //     let values = resultArrAntras[1];

  //     function getObj(total, currentvalue, currentIndex, array) {
  //       total[currentvalue] = values[currentIndex];
  //       return total;
  //     };
  //     keys.reduce(getObj, resultArrTrecias);
  //     resultArrGalutinis.fileInfo = resultArrTrecias;

  //     for (let i = 6; i < resultArrAntras.length - 3; i++) {
  //       let keys = resultArrAntras[5];
  //       let values = resultArrAntras[i];
  //       let res = {};
  //       keys.reduce(getObj, res);
  //       resultArrGalutinis.ataskaitosDuomenys[resultArrAntras[i][5]] = res;
  //       function getObj(total, currentvalue, currentIndex, array) {
  //         total[currentvalue] = values[currentIndex];
  //         return total;
  //       };
  //     }
  //     //  console.log(resultArrGalutinis);
  //     // console.log("Toliau Naujas dirba");
  //     console.log('Trečia funkcijos viduje');

  //     return resultArrGalutinis;

  //   };
  //   reader.readAsText(file)

  //   // .then(()=>{console.log('Ketvirtas data pabaiga')});

  //   async function paskutinis(){
  //     console.log('Ketvirtas data pabaiga');
  //     console.log(resultArrGalutinis);
  //    return ()=>resultArrGalutinis;
  //   }

  //   // return {"Perduodamas_objektas":"Turinys", "Perduodamas_objektas1":"Turinys1"}
  // },
  //================================================

  data: (file) => {
    return new Promise((resolve, reject) => {
      console.log("Pirmas");
      const reader = new FileReader();
      // ------------------------------------
      // let resultArrGalutinis = new Object;
      let resultArrGalutinis = {};
      reader.addEventListener("load", (event) =>
        load().then((resultArrGalutinis) => {
          let perduodamasObjektas = JSON.stringify(resultArrGalutinis);

          // let ass = JSON.stringify({"fileInfo":"fileInfo","Kitas":{"KitasVidinis":"VidinisTurinys"}})

          resolve({ perduodamasObjektas });
          // resolve({resultArrGalutinis});
          console.log(resultArrGalutinis);
          //paskutinis();
        })
      );

      async function load() {
        let arr = reader.result;
        let resultArrAntras = new Array();

        resultArrAntras = Array.from(arr.split("\n")).map((item) => {
          return item.split("\t");
        });

        // console.log(resultArrAntras);

        let resultArrTrecias = new Object();
        resultArrGalutinis.fileInfo = {};
        resultArrGalutinis.ataskaitosDuomenys = {};
        let keys = resultArrAntras[0];
        let values = resultArrAntras[1];

        function getObj(total, currentvalue, currentIndex, array) {
          total[currentvalue] = values[currentIndex];
          return total;
        }
        keys.reduce(getObj, resultArrTrecias);
        resultArrGalutinis.fileInfo = resultArrTrecias;

        for (let i = 6; i < resultArrAntras.length - 3; i++) {
          let keys = resultArrAntras[5];
          let values = resultArrAntras[i];
          let res = {};
          keys.reduce(getObj, res);
          resultArrGalutinis.ataskaitosDuomenys[resultArrAntras[i][5]] = res;
          function getObj(total, currentvalue, currentIndex, array) {
            total[currentvalue] = values[currentIndex];
            return total;
          }
        }
        //  console.log(resultArrGalutinis);
        // console.log("Toliau Naujas dirba");
        console.log("Trečia funkcijos viduje");

        return resultArrGalutinis;
      }
      reader.readAsText(file);
    });
  },
  // beforeUpload: () => {
  //   console.log("this.data");
  //   //  alert("beforeUpload veikia 123");
  //    let data=beforeUpload.data;
  //    console.log(data);
  //   //  const reader = new FileReader();
  //   //  // ------------------------------------
  //   //  let resultArrGalutinis = new Object;
  //   //  reader.addEventListener('load', async (event) => {
  //   //    let arr = reader.result;
  //   //    let resultArrAntras = new Array;

  //   //    resultArrAntras = Array.from(arr.split("\n")).map(item=>{return item.split("\t")});

  //   //    // console.log(resultArrAntras);

  //   //    let resultArrTrecias = new Object;
  //   //    resultArrGalutinis.fileInfo = {};
  //   //    resultArrGalutinis.ataskaitosDuomenys = {};
  //   //    let keys = resultArrAntras[0];
  //   //    let values = resultArrAntras[1];

  //   //    function getObj(total, currentvalue, currentIndex, array) {
  //   //      total[currentvalue] = values[currentIndex];
  //   //      return total;
  //   //      };
  //   //    keys.reduce(getObj, resultArrTrecias);
  //   //    resultArrGalutinis.fileInfo = resultArrTrecias;

  //   //    for(let i = 6; i < resultArrAntras.length - 3; i++) {
  //   //        let keys = resultArrAntras[5];
  //   //        let values = resultArrAntras[i];
  //   //        let res ={};
  //   //        keys.reduce(getObj, res);
  //   //        resultArrGalutinis.ataskaitosDuomenys[resultArrAntras[i][5]] = res;
  //   //        function getObj(total, currentvalue, currentIndex, array) {
  //   //          total[currentvalue] = values[currentIndex];
  //   //          return total;
  //   //          };
  //   //      }
  //   //      console.log(resultArrGalutinis);
  //   //      // console.log("Toliau Naujas dirba");

  //   //     //  return resultArrGalutinis;
  //   //      console.log(data);
  //   //  });
  //   //  // ------------------------------------
  //   // //  this.data=resultArrGalutinis;

  //   // reader.readAsText(file);

  //   //  console.log(resultArrGalutinis);

  //   // const reader = new FileReader();
  //   // reader.onload = file => {
  //   //      console.log(file);

  //   // };
  //   // reader.readAsText(file);
  //   // console.log("beforeUpload veikia!!!!!!!!");
  //   // console.log(file);
  //   // console.log("Gaidys");
  //   // Prevent upload
  //   return false;
  // },
  // customRequest(e) {
  //             // file.preventDefault();
  //             alert("customRequest veikia");

  //             const file = e.file;

  //             const reader = new FileReader();
  //             // debugger;
  //             // ------------------------------------
  //             let resultArrGalutinis = new Object;
  //             reader.addEventListener('load', async (event) => {
  //               let arr = reader.result;
  //               let resultArrAntras = new Array;

  //               resultArrAntras = Array.from(arr.split("\n")).map(item=>{return item.split("\t")});

  //               // console.log(resultArrAntras);

  //               let resultArrTrecias = new Object;
  //               resultArrGalutinis.fileInfo = {};
  //               resultArrGalutinis.ataskaitosDuomenys = {};
  //               let keys = resultArrAntras[0];
  //               let values = resultArrAntras[1];

  //               function getObj(total, currentvalue, currentIndex, array) {
  //                 total[currentvalue] = values[currentIndex];
  //                 return total;
  //                 };
  //               keys.reduce(getObj, resultArrTrecias);
  //               resultArrGalutinis.fileInfo = resultArrTrecias;

  //               for(let i = 6; i < resultArrAntras.length - 3; i++) {
  //                   let keys = resultArrAntras[5];
  //                   let values = resultArrAntras[i];
  //                   let res ={};
  //                   keys.reduce(getObj, res);
  //                   resultArrGalutinis.ataskaitosDuomenys[resultArrAntras[i][5]] = res;
  //                   function getObj(total, currentvalue, currentIndex, array) {
  //                     total[currentvalue] = values[currentIndex];
  //                     return total;
  //                     };
  //                 }

  //                 // console.log("Toliau Naujas dirba");

  //                 console.log(resultArrGalutinis);
  //                 console.log("This.props bandymas");
  //                 // this.props.data=resultArrGalutinis;
  //                 return resultArrGalutinis;

  //             });
  //             // ------------------------------------

  //             reader.readAsText(file);
  //             // reader.readAsArrayBuffer(file);

  //             // reader.onload = ()=> {
  //             //   console.log(resultArrGalutinis)
  //             // }
  //   }
  // data:  (file) => {

  //     console.log('Pirmas');
  //     const reader = new FileReader();

  //     reader.addEventListener('load', (event) => {load()})

  //       // let perduodamasObjektas = JSON.stringify(resultArrGalutinis);

  //       // // let ass = JSON.stringify({"fileInfo":"fileInfo","Kitas":{"KitasVidinis":"VidinisTurinys"}})

  //       // resolve({perduodamasObjektas});
  //       // // resolve({resultArrGalutinis});
  //       // console.log(resultArrGalutinis);
  //       // //paskutinis();

  //     let load = function() {
  //       let resultArrGalutinis = {};
  //       let arr = reader.result;
  //       let resultArrAntras = new Array;

  //       resultArrAntras = Array.from(arr.split("\n")).map(item => { return item.split("\t") });

  //       // console.log(resultArrAntras);

  //       let resultArrTrecias = new Object;
  //       resultArrGalutinis.fileInfo = {};
  //       resultArrGalutinis.ataskaitosDuomenys = {};
  //       let keys = resultArrAntras[0];
  //       let values = resultArrAntras[1];

  //       function getObj(total, currentvalue, currentIndex, array) {
  //         total[currentvalue] = values[currentIndex];
  //         return total;
  //       };
  //       keys.reduce(getObj, resultArrTrecias);
  //       resultArrGalutinis.fileInfo = resultArrTrecias;

  //       for (let i = 6; i < resultArrAntras.length - 3; i++) {
  //         let keys = resultArrAntras[5];
  //         let values = resultArrAntras[i];
  //         let res = {};
  //         keys.reduce(getObj, res);
  //         resultArrGalutinis.ataskaitosDuomenys[resultArrAntras[i][5]] = res;
  //         function getObj(total, currentvalue, currentIndex, array) {
  //           total[currentvalue] = values[currentIndex];
  //           return total;
  //         };
  //       }
  //       //  console.log(resultArrGalutinis);
  //       // console.log("Toliau Naujas dirba");
  //       console.log('Trečia funkcijos viduje');

  //       return resultArrGalutinis;

  //     };
  //     reader.readAsText(file)

  // },
};
export const FileSend = () => {
  return (
    // <Dragger {...props} customRequest={handleSubmit}>
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Spustelėkite arba vilkite failą į šią sritį, kad įkeltumėte
      </p>
      <p className="ant-upload-hint">
        Vieno arba masinio įkėlimo palaikymas. Griežtai draudžiama įkelti įmonės
        duomenis ar kitus konfidencialius failus.
      </p>
    </Dragger>
  );
};

// const FileSendForm = (props) => {

//     function handleSubmit(e) {
//         // Prevent the browser from reloading the page
//         e.preventDefault();
//         // alert("Gaidyssssss");
//         // Read the form data
//         // const form = e.target;
//         // const formData = new FormData(form);
//         // You can pass formData as a fetch body directly:
//         // fetch('/some-api', { method: form.method, body: formData });
//         // You can generate a URL out of it, as the browser does by default:
//         // console.log(new URLSearchParams(formData).toString());
//         // You can work with it as a plain object.
//         // const formJson = Object.fromEntries(formData.entries());
//         // console.log(formJson); // (!) This doesn't include multiple select values
//         // Or you can get an array of name-value pairs.
//         // console.log([...formData.entries()]);
//         // console.log([...formData]);
//         // props.onSubmit(formJson);
//       };

// debugger;
//     return (
//           <Dragger {...props} >
//             <p className="ant-upload-drag-icon">
//               <InboxOutlined />
//             </p>
//             <p className="ant-upload-text">Click or drag file to this area to upload</p>
//             <p className="ant-upload-hint">
//               Support for a single or bulk upload. Strictly prohibited from uploading company data or other
//               banned files.
//             </p>
//           </Dragger>
//     )
// }
// const FileSendReduxForm = reduxForm({ form: 'sendFile' })(FileSendForm)

// export const FileSend = (props) => {
//   const onSubmit = (formData) => {// šitą funkciją perduodame per props į HOC form, kur handleSubmit ją paleidžia
//         // debugger;
//         // console.log(formData);
//         // navigate("/menesine", { state:formData});
//         // alert("Submit veikia");
//         // setSearchParams(formData1);
//         // props.getMenesineThunkCreator(searchParams);
//     }
//     return (<div>
//         <h1>Pateikti nuskaityrus daliklių rodmenis</h1>
//         <FileSendReduxForm onSubmit={onSubmit}
//         />
//     </div>)
// }
//************************** */

// let mapStateToProps = (state) => {
//     // debugger;
//     return {
//         paieskosData: state.data.rezultatas,
//         id: state.data.id
//     }
// }
// // debugger;
// let mapDispatchToProps = (dispatch) => {
//     // debugger;
//     return {
//         getDataThunkCreator: () => { dispatch(getDataThunkCreator())},
//         getMenesineThunkCreator: (data) => {dispatch(getMenesineThunkCreator(data))},
//     }
// }
// const Paieska = connect(mapStateToProps, mapDispatchToProps)(Paieska1);

// //******************** */

// export default Paieska;
