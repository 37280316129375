import React from 'react';
import { NavLink, Outlet, useLocation, useMatch } from "react-router-dom"
import {
  MenuFoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  BarChartOutlined,
  MailOutlined
} from '@ant-design/icons';
import { Button, Image, Layout, Menu, theme, Col, Row, Space } from 'antd';
import { useState } from 'react';
import logo from '../images/logo3.png';
import style from './css/style.module.css';
import { Header1 } from './Header/Header1';
import { HeaderContainer1 } from './Header/HeaderContainer';
import { ManoLink } from './CustomComp/CustomLink';
// import AuthVerify from "./common/auth-verify";


// export const Layout = (props) => {
//     return (
//         <div>
//             <header>
//                 <h1>Viršus</h1>
//                 <ManoLink to="/">Home</ManoLink>
//                 <ManoLink to="/about">About</ManoLink>
//                 <ManoLink to="/blog">Blog</ManoLink>
//                 <ManoLink to="/table">Table</ManoLink>
//             </header>
//             <div>
//                 <h1>Turinys</h1>
//                 <Outlet />
//             </div>


//             <footer> <h1>Apačia 2023</h1></footer>
//         </div>
//     )
// } 

 



const { Header, Sider, Content } = Layout;
export const Layout1 = () => {

//assigning location variable
 const location = useLocation();
  // console.log(location);
 //destructuring pathname from location
 const { pathname } = location;
  // console.log(pathname);
 //Javascript split method to get the name of the path in array
 const splitLocation = pathname.split("/");
  // console.log(splitLocation);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const[selected, setSelected] = useState('1')

  return (
    <Layout>
      <Header style={{
        margin: '0px 0px',
        padding: 0,
        // minHeight: 280,
        background: colorBgContainer,
      }}>
        <HeaderContainer1 collapsed={collapsed} setCollapsed={setCollapsed} />
        
      </Header>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed} style={{
          height: '100%'
        }}
        >
          <div className="demo-logo-vertical" />
          <Menu
            selectable={true}
            selectedKeys={selected}
            theme="dark"
            mode="inline"
            // defaultSelectedKeys={['2']}
            // onClick={(item)=>{setSelected(item.key)}}
            items={[
              {
                key: '1',
                icon: <HomeOutlined />,
                label: <ManoLink to="/" setSelected={setSelected}  k="1">Home</ManoLink>,

              },
              {
                key: '2',
                icon: <UserOutlined />,
                label: <ManoLink to="/users" setSelected={setSelected} k="2">Users</ManoLink>,

              },
              {
                key: '3',
                icon: <BarChartOutlined />,
                label: <ManoLink to="/ataskaitos" setSelected={setSelected} k="3">Ataskaitos</ManoLink>,

              },
              {
                key: '4',
                icon: <VideoCameraOutlined />,
                label: <ManoLink to="/about" setSelected={setSelected} k="4">About</ManoLink>,
              },
              {
                key: '5',
                icon: <UploadOutlined />,
                label: <ManoLink to="/blog" setSelected={setSelected} k="5">Blog</ManoLink>,
              },
              {
                key: '6',
                icon: <BarChartOutlined />,
                label: <ManoLink to="/table" setSelected={setSelected} k="6">Lentelė</ManoLink>,
              },
              {
                key: '7',
                icon: <BarChartOutlined />,
                label: <ManoLink to="/verify" setSelected={setSelected} k="7">Verify</ManoLink>,
              },
              {
                key: '8',
                icon: <BarChartOutlined />,
                label: <ManoLink to="/admin" setSelected={setSelected} k="8">Administravimas</ManoLink>,
              },
              {
                key: '9',
                icon: <MailOutlined />,
                label: <ManoLink to="/email" setSelected={setSelected} k="9">Send email</ManoLink>,
              },
            ]}
          />
        </Sider>
        <Layout>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};